import classnames from 'classnames/bind'
import { forwardRef, useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BannerProps {
  className?: string
  displayed?: boolean
  texts?: RichTextBlocks[]
  innerRef?: any
}

function Banner({ texts, innerRef }: BannerProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Cta11Haffer,
    color: GlobalThemeColors.White,
  })

  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })

  const [shouldStopBannerAnimation, setShouldStopBannerAnimation] =
    useState(false)

  const handleMouseEnter = () => {
    if (!shouldStopBannerAnimation) {
      setShouldStopBannerAnimation(true)
    }
  }

  const handleMouseLeave = () => {
    if (shouldStopBannerAnimation) {
      setShouldStopBannerAnimation(false)
    }
  }

  const speed = shouldStopBannerAnimation ? 0 : -0.5

  const hasOnlyOneText = texts?.length === 1

  const TextItemComponent = ({
    text,
    i,
  }: {
    text: RichTextBlocks
    i: number
  }) =>
    isRTFilled(text) && (
      <div
        key={`banner-text-${i}`}
        className={cx(css.textContainer, {
          [css.hasOnlyOneText]: hasOnlyOneText,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <RichText
          render={texts[i]}
          className={cx(textStyle, css.text)}
          theme={GlobalThemeColors.White}
          ctaPreset={GlobalTextPreset.Cta11Haffer}
          uppercaseLink={false}
        />
      </div>
    )

  return texts ? (
    <div ref={innerRef} className={cx(css.Banner, gridStyle)}>
      <Slider
        infinite
        className={css.slider}
        autoPlay={{ speed }}
        customSliderRef={innerRef}
        dragProps={{ enable: false }}>
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
        {texts?.map((text, i) => (
          <TextItemComponent key={`banner-text-${i}`} text={text} i={i} />
        ))}
      </Slider>
    </div>
  ) : null
}

Banner.defaultProps = {}

function BannerForwarded(props, ref) {
  return <Banner innerRef={ref} {...props} />
}

export default forwardRef<any, BannerProps>(BannerForwarded)
