import { PRISMIC_TYPES } from '~/lib/prismic-types';

export type TLandingType = "venus" | "nina-crush" | null

export type TIsLandingPageReturn = {
  isLandingPage: boolean,
  type?: TLandingType
}

function isLandingPage(documentType: string): TIsLandingPageReturn {

  switch (documentType) {
    case PRISMIC_TYPES.VENUS:
      return {
        isLandingPage: true,
        type: "venus"
      }

    case PRISMIC_TYPES.NINA_CRUSH:
      return {
        isLandingPage: true,
        type: "nina-crush"
      };

    default:
      return {
        isLandingPage: false,
      };
  }
}

export default isLandingPage