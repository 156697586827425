import classnames from 'classnames/bind'
import { ComponentProps } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Accordion } from '@unlikelystudio/react-abstract-components'
import { FiltersPanel as AbstractFiltersPanel } from '@unlikelystudio/react-ecommerce-hooks'

import FormTemplate from '~/components/Abstracts/FormTemplate'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type FiltersPanelProps = {
  closePanel: () => void
}

export function FiltersAndSortPanel({
  defaultValues,
  facetComponent,
  facets,
  onReset,
  onSubmit,
}: Pick<
  ComponentProps<typeof AbstractFiltersPanel>,
  'defaultValues' | 'facetComponent' | 'facets' | 'onSubmit'
> & { onReset?: () => void }) {
  const t = useTranslate()

  const clearStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <FormTemplate
      className={css.form}
      components={{
        button: (props) => {
          return (
            <InlineCta
              type="button"
              href={null}
              textPreset={GlobalTextPreset.Cta12Haffer}
              onClick={(event) => {
                props?.onClick?.(
                  event as unknown as React.MouseEvent<HTMLButtonElement>,
                )

                onReset?.()
              }}
              className={cx(
                clearStyle,
                css.button,
                css.clearButton,
                css.hidden,
              )}>
              {t(FILTER.CLEAR_ALL)}
            </InlineCta>
          )
        },
        input: (props) => {
          if (props?.type === 'submit') {
            return (
              <SquaredCta
                type="submit"
                className={cx(css.button, css.submitButton)}>
                {t(FILTER.VALIDATE)}{' '}
              </SquaredCta>
            )
          }

          return null as unknown as JSX.Element
        },
      }}>
      <Accordion className={css.mobileFiltersAccordion} index={0}>
        <AbstractFiltersPanel
          className={css.panel}
          updateMethod="onSubmit"
          // we have to add an empty function as prop onChange is required
          // this needs to be fixed in react-ecommerce-hooks
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          facetComponent={facetComponent}
          facets={facets}
        />
      </Accordion>
    </FormTemplate>
  )
}
