import classnames from 'classnames/bind'

import Image from '~/components/UI/Image'
import Link from '~/components/UI/Link'

import { ImageItem } from '../types'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 10.5 / 24 },
  { ratio: 10.5 / 24 },
]

export default function NavigationImageItem({
  className,
  image,
  link,
}: ImageItem) {
  return (
    <Link className={cx(css.ImageItem, className)} {...link}>
      {image && (
        <Image
          className={css.image}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          sizesFromBreakpoints={IMAGE_SIZES}
          ratio={css.ratio}
          {...image}
        />
      )}
    </Link>
  )
}
