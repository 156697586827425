import { createContext, useContext, useMemo, useState } from 'react'

import { LandingSliceWrapperProps } from '~/components/UI/_Landing/LandingSliceWrapper'

export const LandingThemeProductButtonContext =
  createContext<LandingProductButtonThemeValueType>({})

interface LandingThemeProductButtonProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface LandingProductButtonThemeValueType {
  themeProductButton?: LandingSliceWrapperProps['themeProductButton']
  setThemeProductButton?: (
    value: LandingSliceWrapperProps['themeProductButton'],
  ) => void
}

export default function LandingThemeProductButtonProvider({
  children,
}: LandingThemeProductButtonProviderProps) {
  const [themeProductButton, setThemeProductButton] =
    useState<LandingSliceWrapperProps['themeProductButton']>('light')

  const processedValue: LandingProductButtonThemeValueType = useMemo(
    () => ({
      themeProductButton,
      setThemeProductButton,
    }),
    [themeProductButton, setThemeProductButton],
  )

  return (
    <LandingThemeProductButtonContext.Provider value={processedValue}>
      {children}
    </LandingThemeProductButtonContext.Provider>
  )
}

export function useLandingThemeProductButtonContext() {
  return useContext(LandingThemeProductButtonContext)
}
