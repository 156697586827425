import classnames from 'classnames/bind'
import { useState, useTransition } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useIsHover } from '@unlikelystudio/react-hooks'

import NavigationImageItem from '~/components/Navigation/Navigation/NavigationDesktopClick/ImageItem'
import { trackClickEventForMenuLink } from '~/components/Navigation/Navigation/utils'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import { MainLinkProps, PanelNavItemLinkProps } from '../types'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavItemLink({ link, className, onClick }: PanelNavItemLinkProps) {
  const [isHovered, setIsHovered] = useState(false)

  const [, mouseEvents] = useIsHover({
    onMouseEnter: () => {
      setIsHovered(true)
    },
    onMouseLeave: () => {
      setIsHovered(false)
    },
  })
  const [isPending, startTransition] = useTransition()

  return (
    <InlineCta
      hasLine={false}
      className={className}
      textPreset={GlobalTextPreset.Cta12Haffer}
      theme={isHovered ? GlobalThemeColors.Black : GlobalThemeColors.DoveGray}
      onClick={() => {
        startTransition(() => {
          onClick && onClick()
        })
      }}
      isLoading={isPending}
      {...mouseEvents}
      {...link}
    />
  )
}

export default function NavigationPanel({
  columns,
  itemLeft,
  itemRight,
  onMouseEnter,
  onMouseLeave,
}: MainLinkProps) {
  const { tracker } = useTracker()
  const [, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={css.NavigationPanel} {...mouseEvents}>
      <div className={css.panelContent}>
        <div className={css.columns}>
          {columns?.map(({ sections }, index) => {
            return (
              <div className={css.column} key={`column_${index}`}>
                {sections?.map(({ title, links }, index) => {
                  return (
                    <div className={css.section} key={`section_${index}`}>
                      {title && (
                        <div className={cx(css.sectionTitle, titleStyle)}>
                          {title}
                        </div>
                      )}
                      <div className={css.sectionLinks}>
                        {links?.map((link, linkIndex) => (
                          <NavItemLink
                            key={`link_${index}_${linkIndex}`}
                            className={css.sectionLink}
                            link={link}
                            onClick={() => {
                              trackClickEventForMenuLink(
                                tracker,
                                link?.children ?? '',
                              )
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className={css.imageItems}>
          {itemLeft && (
            <NavigationImageItem {...itemLeft} className={css.left} />
          )}
          {itemRight && (
            <NavigationImageItem {...itemRight} className={css.right} />
          )}
        </div>
      </div>
    </div>
  )
}
