import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState, useTransition } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import linkResolver from '~/lib/link-resolver'
import { inlineCtaNegativeTheme } from '~/lib/negative-theme-colors'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { useIsHover } from '@unlikelystudio/react-hooks'

import NavigationLogo from '~/components/Navigation/Navigation/commons/NavigationLogo'
import {
  MainLinkProps,
  NavigationProps,
} from '~/components/Navigation/Navigation/types'
import { trackClickEventForMenuLink } from '~/components/Navigation/Navigation/utils'
import PanelCart from '~/components/Panels/CartPanelWrapped'
import InlineCta from '~/components/UI/InlineCta'
import Link from '~/components/UI/Link'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useGetCountProducts from '~/hooks/cart/useGetCountProducts'
import useLocale from '~/hooks/useLocale'
import useShop from '~/hooks/useShop'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import NavigationPanel from './Panel'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function MainLink({
  color,
  link,
  text,
  onMouseEnter,
  isGray,
  disabledLink,
  isClicked,
  theme,
}: MainLinkProps) {
  const { tracker } = useTracker()
  const linkTheme =
    theme === GlobalThemeColors.White
      ? GlobalThemeColors.White
      : !isGray
      ? GlobalThemeColors.Black
      : GlobalThemeColors.DoveGray

  return (
    <div
      className={cx(css.link, { hovered: isClicked })}
      onClick={() => {
        link
          ? () => {
              trackClickEventForMenuLink(tracker, text)
            }
          : null
        onMouseEnter?.()
      }}>
      <InlineCta
        textPreset={GlobalTextPreset.Cta12Haffer}
        textStyling={GlobalTextStyling.UpperCase}
        theme={linkTheme}
        hasLine={false}
        isDiv={!link}
        {...(!disabledLink && link
          ? { ...link, children: text }
          : { children: <span className={css.linkItem}>{text}</span> })}
        style={{ color: color }}
      />
    </div>
  )
}

function NavigationDesktop({
  className,
  theme,
  allLinks,
  leftLinks,
  rightLinks,
  searchLink,
  storeLocatorLink,
  accountLabel,
  cartLabel,
  hasScrolled,
}: NavigationProps) {
  const [_, startTransition] = useTransition()

  const gridStyle = useStyle({
    grid: GlobalGridPreset.BASE_GRID,
  })

  const countProducts = useGetCountProducts()

  const { add: addPanel } = usePanel()

  const openPanel = () => {
    startTransition(() => {
      addPanel({
        component: <PanelCart />,
      })
    })
  }

  const [panelIndex, setPanelIndex] = useState(null)
  const [navItemHovered, setNavItemHovered] = useState(false)

  const locale = useLocale()

  const router = useRouter()
  const shop = useShop()

  const asPanel = (index) =>
    index !== null && allLinks?.[index]?.columns?.length > 0

  const isOpen =
    // Content of the panel
    asPanel(panelIndex) &&
    // Panel hovered or nav item hovered
    navItemHovered

  const computedTheme = hasScrolled || isOpen ? GlobalThemeColors.Black : theme

  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Haffer,
    color: computedTheme,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const countStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: inlineCtaNegativeTheme(computedTheme),
  })

  const closePanel = useCallback(() => {
    setPanelIndex(null)
    setNavItemHovered(false)
  }, [])

  // Listen router to handle route change hide panel
  useEffect(() => {
    closePanel()
  }, [router?.asPath])

  const [, mouseEvents] = useIsHover({
    onMouseLeave: closePanel,
  })

  return (
    <nav
      className={cx(
        css.NavigationDesktop,
        className,
        getCSSThemeClassName(css, 'theme', computedTheme),
        { hasScrolled },
      )}
      {...mouseEvents}>
      {/* Global background black transparent alpha */}
      <AnimatePresence>
        {isOpen && (
          <m.div
            className={css.background}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.4 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.4 },
            }}
          />
        )}
      </AnimatePresence>
      {/* White global panel background */}
      <AnimatePresence>
        {isOpen && (
          <m.div
            className={css.backgroundPanel}
            initial={{ scaleY: 0 }}
            animate={{
              scaleY: 1,
              transition: {
                duration: 0.1,
                ease: [0.195, 0.59, 0.435, 0.825],
              },
            }}
            exit={{
              scaleY: 0,
              transition: {
                duration: 0.2,
                delay: 0.1,
                ease: [0.46, 0.255, 0.74, 0.48],
              },
            }}
          />
        )}
      </AnimatePresence>
      <div className={cx(css.content, { isOpen })}>
        {/* Little top bar white background on scroll */}
        <AnimatePresence>
          {hasScrolled && !isOpen && (
            <m.div
              className={css.backgroundTopBar}
              initial={{ scaleY: 0 }}
              animate={{
                scaleY: 1,
                transition: {
                  duration: 0.2,
                  ease: [0.195, 0.59, 0.435, 0.825],
                },
              }}
              exit={{
                scaleY: 0,
                transition: {
                  duration: 0.2,
                  ease: [0.46, 0.255, 0.74, 0.48],
                },
              }}
            />
          )}
        </AnimatePresence>
        <div className={gridStyle}>
          <div className={css.links}>
            {leftLinks?.map((link, index) => (
              <MainLink
                {...link}
                theme={computedTheme}
                key={`main_link_${index}`}
                isGray={(navItemHovered || isOpen) && index !== panelIndex}
                onMouseEnter={() => {
                  panelIndex !== index ||
                  (panelIndex === index && navItemHovered === false)
                    ? setNavItemHovered(true)
                    : setNavItemHovered(false)
                  setPanelIndex(index)
                }}
                disabledLink={link?.columns?.length > 0}
              />
            ))}
          </div>
          <div className={css.logoWrapper}>
            <NavigationLogo className={css.logo} theme={computedTheme} />
          </div>
          <div className={css.rightLinks}>
            {rightLinks?.map((link, index) => {
              // Offsetting the index here
              const computedIndex = (leftLinks?.length ?? 0) + index

              return (
                <MainLink
                  {...link}
                  theme={computedTheme}
                  key={`main_link_${computedIndex}`}
                  isGray={
                    (navItemHovered || isOpen) && computedIndex !== panelIndex
                  }
                  onMouseEnter={() => {
                    panelIndex !== computedIndex ||
                    (panelIndex === computedIndex && navItemHovered === false)
                      ? setNavItemHovered(true)
                      : setNavItemHovered(false)
                    setPanelIndex(computedIndex)
                  }}
                  disabledLink={link?.columns?.length > 0}
                />
              )
            })}
            {searchLink && (
              <Link
                className={cx(css.link, linkStyle)}
                href={linkResolver({ type: PRISMIC_TYPES.SEARCH }, locale)}>
                {searchLink?.children}
              </Link>
            )}
            {!shop && storeLocatorLink && (
              <Link
                className={cx(css.link, linkStyle)}
                href={linkResolver(
                  { type: PRISMIC_TYPES.STORE_LOCATOR },
                  locale,
                )}>
                {storeLocatorLink?.children}
              </Link>
            )}
            {shop && (
              <Link
                className={cx(css.link, linkStyle)}
                aria-label={accountLabel}
                href={linkResolver({ type: PRISMIC_TYPES.ACCOUNT }, locale)}>
                {accountLabel}
              </Link>
            )}
            {shop && (
              <button
                className={cx(css.link, linkStyle)}
                onClick={openPanel}
                data-testid="button-cart">
                <span>{cartLabel}</span>
                {countProducts > 0 && (
                  <span className={css.cartContainer}>
                    <span>&nbsp;({countProducts})</span>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Container with opacity animation for panel */}
      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2, delay: 0.2 },
            }}
            exit={{
              opacity: [1, 0, 0],
              transition: {
                duration: 0.3,
                times: [0, 0.3, 1],
              },
            }}
            className={cx(css.panelContainer, { isOpen })}>
            <NavigationPanel {...allLinks[panelIndex]} />
          </m.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

export default NavigationDesktop
