import { PRISMIC_SLICES } from '~/lib/prismic-types'

const SLICES_WITHOUT_MARGIN_TOP = [
  PRISMIC_SLICES.HERO_MAIN,
  PRISMIC_SLICES.HERO_MAIN_TEASING,
  PRISMIC_SLICES.HERO_MAIN_TEASING_SCROLL,
  PRISMIC_SLICES.DIPTYCH_OR_TRIPTYCH
]

export default SLICES_WITHOUT_MARGIN_TOP
