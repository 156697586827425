import { createContext, useContext, useMemo, useState } from 'react'

import { LandingSliceWrapperProps } from '~/components/UI/_Landing/LandingSliceWrapper'

export const LandingThemeColorNavigationContext =
  createContext<LandingNavigationColorThemeValueType>({})

interface LandingThemeColorNavigationProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface LandingNavigationColorThemeValueType {
  themeColorNavigation?: LandingSliceWrapperProps['themeColorNavigation']
  setThemeColorNavigation?: (
    value: LandingSliceWrapperProps['themeColorNavigation'],
  ) => void
}

export default function LandingThemeColorNavigationProvider({
  children,
}: LandingThemeColorNavigationProviderProps) {
  const [themeColorNavigation, setThemeColorNavigation] =
    useState<LandingSliceWrapperProps['themeColorNavigation']>('light')

  const processedValue: LandingNavigationColorThemeValueType = useMemo(
    () => ({
      themeColorNavigation,
      setThemeColorNavigation,
    }),
    [themeColorNavigation, setThemeColorNavigation],
  )

  return (
    <LandingThemeColorNavigationContext.Provider value={processedValue}>
      {children}
    </LandingThemeColorNavigationContext.Provider>
  )
}

export function useLandingThemeColorNavigationContext() {
  return useContext(LandingThemeColorNavigationContext)
}
