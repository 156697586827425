import Head from 'next/head'

import multistore from 'config/multistore.json'

import { ImageProps } from '~/components/UI/Image'

import useLocale from '~/hooks/useLocale'

import { isDefaultLocale } from '~/utils/locales'

export interface Language {
  id: string
  uid: string
  lang: string
  url: string
  type?: string
  locale: string
}

export interface MetaProps extends Language {
  langSwitcher: Language[]
  alternateLanguages: Language[]
  title?: string
  description?: string
  image?: ImageProps
  isRootPage?: boolean
}

function Meta(meta: MetaProps) {
  const { title, description, image, url, alternateLanguages } = meta
  const locale = useLocale()

  const metaTitle = title ? `${title}` : ''
  const metaDesc = description ?? null
  const metaImage = image ?? null
  const metasUrl = url ?? null

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${metaTitle}`} key="title" />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {metaImage && <meta property="og:image" content={metaImage?.src} />}

      {alternateLanguages?.map(({ locale, url, lang }) => (
        <link
          rel="alternate"
          href={url}
          hrefLang={isDefaultLocale(locale) ? 'x-default' : lang}
          key={`alternate-${locale}`}
        />
      ))}

      {/* Add the current locale for the SEO */}
      <link
        rel="alternate"
        href={url}
        hrefLang={
          isDefaultLocale(locale) ? 'x-default' : multistore?.[locale]?.intl
        }
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metasUrl} />
      <meta property="twitter:title" content={`${metaTitle}`} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImage && <meta property="twitter:image" content={metaImage?.src} />}
      {metasUrl && (
        <link rel="canonical" href={metasUrl} key={`canonical-${metasUrl}`} />
      )}
    </Head>
  ) : null
}

export default Meta
