import classnames from 'classnames/bind'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalInputPreset } from '~/@types/input-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'
import { GYGIA_TAGS_KEYS } from '~/lib/shopify-tags'

import { Spinner } from '@unlikelystudio/react-abstract-components'

import Input, { InputProps } from '~/components/Abstracts/Form/Input'
import { useInputPreset } from '~/components/Abstracts/Form/Input/hooks'
import { RichTextBlocks } from '~/components/Abstracts/RichText'
import Checkbox from '~/components/Form/Checkbox'
import ErrorList from '~/components/Form/ErrorList'

import RecaptchaHandler from '~/providers/RecaptchaProvider/RecaptchaHandler'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useShopifyNewsletterMutation from '~/hooks/account/useShopifyNewsletterMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import { FORM } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NewsletterProps extends InputProps {
  className?: string
  formClassName?: string
  inputClassName?: string
  resultClassName?: string
  gdprClassName?: string
  fromTag?: GYGIA_TAGS_KEYS
  footer?: JSX.Element | JSX.Element[]
  gdprLabel?: RichTextBlocks
  gdprLegals?: RichTextBlocks
  onSuccess?(): void
  theme?: 'dark' | 'light'
}

function Newsletter({
  className,
  formClassName,
  inputClassName,
  resultClassName,
  gdprClassName,
  children,
  placeholder,
  gdprLabel,
  fromTag,
  onSuccess,
  theme = 'dark',
  ...rest
}: NewsletterProps) {
  const t = useTranslate()
  const { tracker } = useTracker()

  const props = useInputPreset(GlobalInputPreset.Email, {
    placeholder: placeholder || t(FORM.NEWSLETTER_EMAIL),
    ...rest,
  })

  const inputStyle = useStyle({
    textPreset: GlobalTextPreset.Input11Haffer,
    color:
      theme === 'dark' ? GlobalThemeColors.DoveGray : GlobalThemeColors.Alto,
  })

  const buttonStyle = useStyle({
    textPreset: GlobalTextPreset.Input11Haffer,
    color: theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.Alto,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const gdprStyle = useStyle({
    textPreset: GlobalTextPreset.Text11Haffer,
    color:
      theme === 'dark' ? GlobalThemeColors.DoveGray : GlobalThemeColors.Alto,
  })

  const [result, setResult] = useState(false)

  const { mutate: newsletterMutation, isLoading } =
    useShopifyNewsletterMutation(
      {
        onSuccess: () => {
          setResult(t(FORM.NEWSLETTER_SUCCESS))
          onSuccess?.()
        },
      },
      (...params) => form.setError(...params),
    )

  const onInvalidFormSubmitCallback = useCallback(
    (errors) => {
      if (errors) {
        const errorNames = Object.keys(errors)
          .map((error) => error)
          .toString()

        tracker.emit(TRACKING_EVENTS.TAGGED_EVENT, {
          eventName: 'subscriptionError',
          eventAction: 'newsletter',
          eventCategory: 'form',
          eventLabel: `error: ${errorNames} invalid or missing`,
        })
      }
    },
    [tracker],
  )

  const { form, onSubmit } = useFormWithMutation(
    newsletterMutation,
    null,
    'newsletter',
    onInvalidFormSubmitCallback,
  )

  useEffect(() => {
    if (
      form?.formState?.errors &&
      Object.keys(form.formState.errors).length > 0
    )
      setResult(false)
  }, [form.formState])

  return (
    <div className={cx(className)}>
      <Fragment>
        {children}

        <RecaptchaHandler>
          <FormProvider {...form}>
            <form className={cx(formClassName)} onSubmit={onSubmit}>
              <div className={cx(css.inputContainer)}>
                <Input
                  className={cx(inputClassName, css.input, inputStyle, {
                    theme,
                  })}
                  onChange={() => setResult(false)}
                  {...props}
                />
                {!result && (
                  <ErrorList className={cx(css.errorList)} name={props?.name} />
                )}

                <div className={css.submitButton}>
                  {isLoading ? (
                    <Spinner className={cx(css.spinner)} />
                  ) : (
                    <button
                      type="submit"
                      className={buttonStyle}
                      aria-label={t(FORM.SUBMIT)}>
                      {t(FORM.SUBMIT)}
                    </button>
                  )}
                </div>
              </div>

              {/* Gygia Tag */}
              <Input type="hidden" name="from" value={fromTag} />
              <Checkbox
                id="gdpr"
                name="gdpr"
                required={true}
                className={cx(css.gdpr, gdprStyle, gdprClassName)}
                theme={
                  theme === 'dark'
                    ? GlobalThemeColors.DoveGray
                    : GlobalThemeColors.Alto
                }
                label={gdprLabel}
              />
              {result && (
                <div className={cx(resultClassName, css.result, inputStyle)}>
                  {result}
                </div>
              )}
            </form>
          </FormProvider>
        </RecaptchaHandler>
      </Fragment>
    </div>
  )
}

Newsletter.defaultProps = {}

export default Newsletter
