export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ONLY_NUMBERS_REGEX = /^[0-9]*$/

export const ONLY_LETTERS_REGEX = /^[A-Za-z]+$/

export const ADDRESS_REGEX = /^[A-zÀ-ÖØ-öø-ÿ-' -]+$/

export const TEL_REGEX = /\+?([\d|(][h|(\d{3})|.|\-|\d]{4,}\d)/

export const INVALID_CHARS_GIFT_CARD = /[æœ¥™©®µ¿·|º§¤≠¬^†«»☼☽☾☿♁♃♄♅♆♇⠁⠂⠃⠄⠅⠆⠇]/g;

