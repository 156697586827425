import { createContext, useContext, useMemo, useState } from 'react'

import { TIsLandingPageReturn } from '~/utils/is-landing-page'

export const LandingTypeContext = createContext<LandingTypeType>({})

interface LandingTypeProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface LandingTypeType {
  landingType?: TIsLandingPageReturn['type']
  isLandingPage?: TIsLandingPageReturn['isLandingPage']
  setLandingType?: (value: TIsLandingPageReturn['type']) => void
}

export default function LandingTypeProvider({
  children,
}: LandingTypeProviderProps) {
  const [landingType, setLandingType] =
    useState<LandingTypeType['landingType']>(null)

  const processedValue: LandingTypeType = useMemo(
    () => ({
      landingType,
      isLandingPage: !!landingType,
      setLandingType,
    }),
    [landingType, setLandingType],
  )

  return (
    <LandingTypeContext.Provider value={processedValue}>
      {children}
    </LandingTypeContext.Provider>
  )
}

export function useLandingTypeContext() {
  return useContext(LandingTypeContext)
}
