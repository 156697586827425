import { UnlikelyImage } from '@unlikelystudio/commerce-connector'
import {
  imageLoaderPrismic as imageLoader,
  LoaderParams,
} from '@unlikelystudio/react-abstract-components'

export interface Image {
  src: string
  width: number
  height: number
  alt?: string
  resizeRect?: string
}

export interface ImageOptions {
  url?: string
  dimensions?: {
    width: number
    height: number
  }
  alt?: string
}

export interface PrismicImageOptions extends ImageOptions {
  card?: ImageOptions
  card1x?: ImageOptions
  card2x?: ImageOptions
}

/**
 * The function isPrismicImage checks if an object is of type ImageOptions by checking if it has a
 * 'dimensions' property.
 * @param {ImageOptions | UnlikelyImage} image - The `image` parameter can be of type `ImageOptions` or
 * `UnlikelyImage`.
 * @returns a boolean value.
 */
function isPrismicImage(
  image: ImageOptions | UnlikelyImage,
): image is ImageOptions {
  return image && 'dimensions' in image
}

export default function serializeImage(
  image: PrismicImageOptions | UnlikelyImage,
  loaderParams?: LoaderParams,
): Image {
  const src = image?.url
  const width = isPrismicImage(image)
    ? image.dimensions.width
    : image?.width ?? null
  const height = isPrismicImage(image)
    ? image?.dimensions?.height
    : image?.height ?? null
    // If the image is a Prismic image, the alt text is stored in the 'alt' property. If the image is
    // SEO image, the alt text is stored in the 'altText' property.
  const alt = isPrismicImage(image) ? image?.alt : image?.altText ?? "Nina Ricci"

  let newImageSrc: string = null
  let resizeRect: string = null

  if (src) {
    const url = new URL(src)
    newImageSrc = `${url?.origin}${url?.pathname}`
    newImageSrc = loaderParams
      ? imageLoader({
          src: newImageSrc,
          ...loaderParams,
        })
      : newImageSrc

    const params = new URL(src).searchParams
    resizeRect = params?.get('rect')
  }

  return src
    ? {
        src: newImageSrc ?? src,
        width: width ?? null,
        height: height ?? null,
        alt,
        resizeRect,
      }
    : null
}
