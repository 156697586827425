import type { BreadcrumbDataProps } from "@unlikelystudio/react-abstract-components"

import getAlternateUrl from "~/utils/alternate-url"

export function serializeBreadcrumbJsonLd(items): BreadcrumbDataProps {
  return {
    items:
      items
        ?.map((item) => ({
          name: item?.children?.toString() ?? undefined,
          url: getAlternateUrl(item?.href) ?? undefined,
        }))
        ?.filter(Boolean) ?? [],
  }
}
