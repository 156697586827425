import type { BreadcrumbDataProps } from "./index"

export default function serializeJSONLD({ items }: BreadcrumbDataProps) {
  const processed = items?.map((item, index) => ({
    "@type": "ListItem",
    position: index + 1,
    name: item?.name,
    item: item.url,
  }))

  return JSON.stringify({
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: processed?.flat() ?? [],
  })
}
