import serializeJSONLD from './serialize-json-ld'

export interface ItemBreadcrumbProps {
  name?: string
  url?: string
}

export interface BreadcrumbDataProps {
  items?: ItemBreadcrumbProps[]
}

export function BreadcrumbData(props: BreadcrumbDataProps) {
  return (
    <script
      id="breadcrumb-json-ld-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: serializeJSONLD(props),
      }}
    />
  )
}
