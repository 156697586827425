import React, { forwardRef, useCallback } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import Link, { LinkProps } from '~/components/Abstracts/Link'

import { useTracker } from '~/providers/TrackerProvider'

import useTrackingPromotionClick from '~/hooks/useTrackingPromotionClick'

type UILinkProps = LinkProps & {
  position?: string
  innerRef?: React.Ref<HTMLAnchorElement>
}

function UILink({ isExternal, innerRef, ...props }: UILinkProps) {
  const handleTrackingPromotionClick = useTrackingPromotionClick(
    props?.position,
  )
  const { tracker } = useTracker()

  const onClick = useCallback(
    (e) => {
      if (isExternal) {
        tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
          eventName: 'outboundLink',
          eventAction: props?.href as string,
          eventCategory: 'outboundlink',
          eventLabel: undefined,
          interaction: {
            outboundLink: {
              link: props?.href,
              type: 'external',
            },
          },
        })
      }

      handleTrackingPromotionClick?.()
      props.onClick?.(e)
    },
    [props, tracker, handleTrackingPromotionClick],
  )

  return <Link {...props} ref={innerRef} onClick={onClick} />
}

UILink.defaultProps = {}

function LinkForwarded(props, ref) {
  return <UILink innerRef={ref} {...props} />
}

export type { UILinkProps as LinkProps }

export default forwardRef<HTMLAnchorElement, LinkProps>(LinkForwarded)
