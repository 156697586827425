import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import AbstractBreadcrumb from '~/components/Abstracts/Breadcrumb'
import { BreadcrumbData } from '~/components/Abstracts/JSONLD/Breadcrumb'

import { useBreadCrumb } from '~/providers/BreadcrumbProvider'
import { useStyle } from '~/providers/StyleProvider'

import { serializeBreadcrumbJsonLd } from '~/data/json-ld/breadcrumb/serialize-json-ld'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BreadcrumbProps {
  className?: string
  theme?: 'dark' | 'light'
}

function Breadcrumb({ className, theme = 'dark', ...rest }: BreadcrumbProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color:
      theme === 'dark' ? GlobalThemeColors.DoveGray : GlobalThemeColors.White,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const { links } = useBreadCrumb()

  return (
    <>
      <AbstractBreadcrumb
        className={cx(css.Breadcrumb, textStyle, className)}
        itemClassName={css.item}
        separatorClassName={css.separator}
        links={links}
        {...rest}
      />
      {links && <BreadcrumbData {...serializeBreadcrumbJsonLd(links)} />}
    </>
  )
}

Breadcrumb.defaultProps = {}

export default Breadcrumb
