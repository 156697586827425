import { SHOPIFY_CUSTOM_ATTRIBUTES_GIFT_MESSAGE } from '~/lib/shopify/constants'

import useGetCart from '~/hooks/cart/useGetCart'

export default function useGetCountProducts() {
  const { cart } = useGetCart()

  const isGiftMessage = Boolean(
    cart?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES_GIFT_MESSAGE.withGiftMessage],
  )

  if (!cart?.products?.length) return 0

  return cart?.products?.length
    ? cart?.products?.reduce((acc, curr) => {
        return (acc += curr.quantity)
      }, 0) + (isGiftMessage ? 1 : 0)
    : null
}
