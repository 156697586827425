import classnames from 'classnames/bind'
import Head from 'next/head'
import { useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'

import { NavigationData } from '@unlikelystudio/react-abstract-components'
import {
  useIsomorphicLayoutEffect,
  useMeasureObserver,
} from '@unlikelystudio/react-hooks'

import NavigationDesktopClick from '~/components/Navigation/Navigation/NavigationDesktopClick'
import NavigationMobile from '~/components/Navigation/Navigation/NavigationMobile'
import type { NavigationProps } from '~/components/Navigation/Navigation/types'
import Banner from '~/components/UI/Banner'

import { useBanner } from '~/providers/BannerProvider'
import { useLandingTypeContext } from '~/providers/_Landing/LandingTypeProvider'

import { serializeJSONLD } from '~/data/json-ld/navigation/serialize-json-ld'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function Navigation({
  className,
  banner,
  isDisabled,
  hasAnimation = true,
  ...rest
}: NavigationProps) {
  const [inViewRef, inView] = useInView({ initialInView: true })
  const [inViewIsFixedRef, inViewIsFixed] = useInView({ initialInView: true })
  const [isDOMLoaded, setIsDOMLoaded] = useState(false)
  const { isLandingPage } = useLandingTypeContext()

  const bannerRef = useRef(null)
  const { height: bannerHeight } = useMeasureObserver(
    bannerRef,
    'getBoundingClientRect',
  )
  const { isVisible: isBannerVisible } = useBanner()

  const cssVariables = useMemo(
    () =>
      `body {
        --banner-height: ${bannerHeight}px;
        --banner-displayed: ${
          !isBannerVisible || !banner?.texts?.length ? 0 : 1
        };
      }`,
    [bannerHeight, isBannerVisible, banner],
  )

  // We need this variable to avoid a jump when the page is loaded
  useIsomorphicLayoutEffect(() => {
    if (!isDOMLoaded) {
      setIsDOMLoaded(true)
    }
  })

  return (
    <>
      {banner?.displayed && banner?.texts?.length > 0 && isBannerVisible && (
        <Banner ref={bannerRef} {...banner} />
      )}

      <Head>{cssVariables && <style>{cssVariables}</style>}</Head>

      {rest?.allLinks && <NavigationData {...serializeJSONLD(rest.allLinks)} />}

      <div
        ref={inViewIsFixedRef}
        className={cx(css.marker, css.NavigationIsFixedRef)}
      />
      <div
        ref={inViewRef}
        className={cx(css.marker, css.NavigationScrollRef)}
      />
      {!isDisabled && (
        <header
          className={cx(css.Navigation, className, {
            isLoaded: isDOMLoaded && isBannerVisible,
            isLandingPage: isLandingPage,
            isFixed: !inViewIsFixed,
            hasScrolled: hasAnimation ? !inView : false,
          })}>
          <NavigationDesktopClick
            className={cx(css.hideOnSmallScreen, css.desktopNavigation)}
            hasScrolled={hasAnimation ? !inView : false}
            {...rest}
          />
          <NavigationMobile
            className={cx(css.hideOnLargeScreen, css.mobileNavigation)}
            hasScrolled={hasAnimation ? !inView : false}
            {...rest}
          />
        </header>
      )}
    </>
  )
}

Navigation.defaultProps = {
  theme: GlobalThemeColors.Black,
}

export default Navigation
