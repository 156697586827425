import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { SupFooterProps } from '~/@types/footer'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import RichText from '~/components/Abstracts/RichText'
import SliderNavigation, {
  SliderNavigationType,
} from '~/components/Navigation/SliderNavigation'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 1 / 24 }]

type TSupFooterProps = {
  theme?: 'dark' | 'light'
} & SupFooterProps

function SupFooter({
  className,
  itemClassName,
  items,
  theme = 'dark',
}: TSupFooterProps) {
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.White,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Text11Haffer,
    color:
      theme === 'dark' ? GlobalThemeColors.DoveGray : GlobalThemeColors.White,
  })

  const customSliderRef = useRef()

  const [
    { slideIndex, maxSlideIndex, setSlideIndex, enableDrag },
    setSliderState,
  ] = useSliderState()

  return (
    <div
      ref={customSliderRef}
      className={cx(css.SupFooter, className, { enableDrag })}>
      {items?.length > 0 && (
        <Slider
          className={css.slider}
          snap={true}
          infinite={true}
          setSliderState={setSliderState}
          customSliderRef={customSliderRef}>
          {items?.map(({ image, name, label }, index) => (
            <div
              key={`${name}_${index}`}
              className={cx(css.item, itemClassName)}>
              <div className={cx(css.top)}>
                {image && (
                  <Image
                    className={cx(css.image)}
                    sizesFromBreakpoints={IMAGE_SIZES}
                    layout="responsive"
                    {...image}
                  />
                )}

                <RichText className={cx(css.name, nameStyle)} render={name} />
              </div>

              <div className={cx(css.bottom)}>
                <RichText
                  className={cx(css.label, labelStyle)}
                  render={label}
                />
              </div>
            </div>
          ))}
        </Slider>
      )}
      {items?.length > 1 && (
        <SliderNavigation
          className={cx(css.sliderNavigation, css.hideOnLargeScreen)}
          type={SliderNavigationType.DotSmall}
          max={maxSlideIndex + 1}
          index={slideIndex}
          setIndex={setSlideIndex}
        />
      )}
    </div>
  )
}

SupFooter.defaultProps = {}

export default SupFooter
