import { TIsLandingPageReturn } from '~/utils/is-landing-page'

function getCurrentLandingNavigationType(landingType: TIsLandingPageReturn['type']) {
  switch (landingType) {
    case 'venus':
      return 'venusNavigation'

    case 'nina-crush':
      return 'ninaCrushNavigation'

    default: return null
  }
}

export default getCurrentLandingNavigationType