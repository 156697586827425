import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractCheckbox, {
  CheckboxProps as AbstractCheckboxProps,
} from '~/components/Abstracts/Form/Checkbox'
import {
  TCheckboxPresets,
  useCheckboxPreset,
} from '~/components/Abstracts/Form/Checkbox/hooks'
import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ErrorList from '~/components/Form/ErrorList'
import { CheckIcon } from '~/components/Icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type CheckBoxTheme =
  | GlobalThemeColors.Black
  | GlobalThemeColors.DoveGray
  | GlobalThemeColors.White
  | GlobalThemeColors.Alto

export interface CheckboxProps extends Omit<AbstractCheckboxProps, 'label'> {
  className?: string
  verticalAlign?: 'center' | 'baseline'
  theme?: CheckBoxTheme
  label?: RichTextBlocks | string
  preset?: TCheckboxPresets
  children?: JSX.Element | JSX.Element[]
  onLabelLinkClick?(): void
  onContainerClick?(): void
}

function Checkbox({
  className,
  preset,
  theme,
  children,
  label,
  onLabelLinkClick,
  onContainerClick,
  verticalAlign = 'center',
  ...rest
}: CheckboxProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Text11Haffer,
    color: theme,
  })

  const { ...props } = useCheckboxPreset(preset, rest)

  const processedLabel =
    typeof label === 'string' ? (
      label
    ) : (
      <RichText
        onClick={onLabelLinkClick}
        render={label}
        withoutCtaTextStyle
        uppercaseLink={false}
      />
    )

  return (
    <div
      className={cx(
        css.Checkbox,
        className,
        css?.[`theme-${theme}`],
        css?.[`vertical-align-${verticalAlign}`],
      )}
      onPointerDown={onContainerClick}>
      <AbstractCheckbox
        errorClassname={cx(css.error)}
        checkmarkClassName={cx(css.checkmark)}
        checkmarkActiveClassName={cx(css.checkmarkActive)}
        contentClassName={cx(css.content)}
        labelClassName={cx(css.label, labelStyle)}
        checkmarkActive={<CheckIcon className={cx(css.checkIcon)} />}
        {...props}
        label={processedLabel}
      />
      {children}
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

Checkbox.defaultProps = {
  theme: GlobalThemeColors.Black,
}

export default Checkbox
