import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'

import PrismicRichText from '~/components/Abstracts/Prismic'
import type {
  RichTextBlock,
  RichTextProps as PrismicRichTextProps,
} from '~/components/Abstracts/Prismic/types'
import type {
  InlineCtaColors,
  InlineCtaProps,
  InlineCtaTextPreset,
} from '~/components/UI/InlineCta'

import useLocale from '~/hooks/useLocale'

import { isRTFilled } from '~/utils/check-empty-string'

import getHtmlSerializer, { HtmlSerializeProps } from './get-html-serializer'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RichTextProps extends PrismicRichTextProps {
  className?: string
  children?: undefined
  innerRef?: any
  ctaPreset?: InlineCtaTextPreset
  theme?: InlineCtaColors
  withoutCtaTextStyle?: InlineCtaProps['withoutTextStyle']
  hasLine?: InlineCtaProps['hasLine']
  uppercaseLink?: boolean
  onClick?: HtmlSerializeProps['onClick']
}

function RichText({
  className,
  innerRef,
  render,
  ctaPreset,
  theme,
  hasLine,
  withoutCtaTextStyle = false,
  uppercaseLink = true,
  onClick,
  ...props
}: RichTextProps) {
  const locale = useLocale()

  const htmlSerializer = getHtmlSerializer({
    ctaPreset,
    theme,
    withoutCtaTextStyle,
    locale,
    hasLine,
    onClick,
  })

  return isRTFilled(render) ? (
    <div
      ref={innerRef}
      className={cx(className, css.RichText, { uppercaseLink })}>
      {/* @ts-ignore */}
      <PrismicRichText
        htmlSerializer={htmlSerializer}
        render={render}
        {...props}
      />
    </div>
  ) : null
}

export type RichTextBlocks = RichTextBlock[]

function RichTextForwarded(props, ref) {
  return <RichText innerRef={ref} {...props} />
}
export default forwardRef<HTMLDivElement, RichTextProps>(RichTextForwarded)
