import linkResolver from '~/lib/link-resolver'

const defaultLink = { href: '/', rel: 'noopener' }

export default function serializeLink(link, locale) {
  if (!link?.link_type) return defaultLink

  switch (link?.link_type) {
    case 'Web':
      return {
        href: link?.url ?? null,
        target: link?.target ?? null,
        isExternal: true,
        rel: 'noopener nofollow',
      }
    case 'Media':
      return {
        href: link?.url ?? null,
        target: '_blank',
        rel: 'nofollow',
        isExternal: true,
      }
    case 'Document':
      return link?.type
        ? { href: linkResolver(link, locale) ?? null, rel: 'noopener' }
        : defaultLink

    case 'Any':
      return null

    default:
      return defaultLink
  }
}
