export enum GlobalTextPreset {
  /** Rich Texts */
  DefaultRichText = 'rich-text',

  /** 2024 REBRAND */
  /** TITLES */
  Title11Haffer = 'title-11-haffer',
  Title11_12Haffer = 'title-11-12-haffer',
  Title12Haffer = 'title-12-haffer',
  Title12_14Haffer = 'title-12-14-haffer',
  Title12_18Haffer = 'title-12-14-haffer',
  Title14Haffer = 'title-14-haffer',
  Title14_16Haffer = 'title-14-16-haffer',
  Title14_18Haffer = 'title-14-18-haffer',
  Title16Haffer = 'title-16-haffer',
  Title16_18Haffer = 'title-16-18-haffer',
  Title18Haffer = 'title-18-haffer',
  Title16_22Haffer = 'title-16-22-haffer',
  Title18_22Haffer = 'title-18-22-haffer',
  Title22Haffer = 'title-22-haffer',
  Title22_16Haffer = 'title-22-16-haffer',
  Title22_30Haffer = 'title-22-30-haffer',

  /** Texts */
  Text11Haffer = 'text-11-haffer',
  Text12Haffer = 'text-12-haffer',
  Text11_12Haffer = "text-11-12-haffer",

  /** Labels */
  Label11Haffer = 'label-11-haffer',
  Label11_12Haffer = 'label-11-12-haffer',
  Label12Haffer = 'label-12-haffer',
  Label12_11Haffer = 'label-12-11-haffer',

  /** Ctas */
  Cta11Haffer = 'cta-11-haffer',
  Cta12Haffer = 'cta-12-haffer',

  /** Inputs */
  Input11Haffer = 'input-11-haffer',
  Input12Haffer = 'input-12-haffer',
  Input14Haffer = 'input-14-haffer',
}
