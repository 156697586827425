import classnames from 'classnames/bind'
import { useEffect } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import type { TPrismicTypesKey } from '~/lib/prismic-types'
import { PRISMIC_TYPES, isPageWithoutMarginTop } from '~/lib/prismic-types'

import Meta from '~/components/Abstracts/Meta'
import PanelManager from '~/components/Abstracts/Panels/PanelManager'
import PopInManager from '~/components/Abstracts/PopInManager'
import { VercelSpeedInsights } from '~/components/Abstracts/VercelSpeedInsights'
import LandingNavigation from '~/components/Navigation/LandingNavigation'
import Navigation from '~/components/Navigation/Navigation'
import { usePopinContests } from '~/components/Popins/PopinContests/hook'
import { CustomAlerts } from '~/components/UI/Alerts'
import Footer from '~/components/UI/Footer'
import PopInNewsletterHandler from '~/components/UI/PopInNewsletterHandler'

import { useLandingTypeContext } from '~/providers/_Landing/LandingTypeProvider'

import useGeopositionRedirect from '~/hooks/useGeopositionRedirect'

import getCurrentLandingNavigationType from '~/utils/get-current-landing-navigation-type'
import isLandingPage from '~/utils/is-landing-page'

import withPrivate from '~/hocs/withPrivate'

import { DefaultPageData } from '~/data/page-data/serializer'

import SLICES_WITHOUT_MARGIN_TOP from './slices-without-margin-top'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PageProps extends DefaultPageData {
  children?: JSX.Element | JSX.Element[]
}
function Page({
  children,
  metas,
  global,
  slices,
  document,
  navigationTheme,
  navigationDisabled,
  popInNewsletterDisabled,
}: PageProps) {
  const { setLandingType } = useLandingTypeContext()

  const withoutMarginTop =
    SLICES_WITHOUT_MARGIN_TOP.indexOf(slices?.[0]?.type) > -1
  const pageHasNoMarginTop =
    isPageWithoutMarginTop(document?.type as TPrismicTypesKey) ?? false

  useGeopositionRedirect()
  usePopinContests()

  const landingPage = isLandingPage(document?.type)
  const isVenusPage = landingPage?.type === 'venus'

  const isHeroTitleOnly = Array.isArray(children)
    ? children[0]?.props?.isHeroTitleOnly
    : children?.props?.isHeroTitleOnly

  const landingType = isLandingPage(document?.type)?.type
  const currentLandingNavigationType =
    getCurrentLandingNavigationType(landingType)
  const currentLandingNavigationItems = global?.[currentLandingNavigationType]

  useEffect(() => {
    setLandingType(landingType)
    return () => {
      setLandingType(null)
    }
  }, [landingType])

  return (
    <>
      <Meta {...metas} isRootPage={document?.type === PRISMIC_TYPES.HOMEPAGE} />
      {!global?.newsletter?.disabled && !popInNewsletterDisabled && (
        <PopInNewsletterHandler />
      )}

      {landingPage?.isLandingPage && currentLandingNavigationItems ? (
        <LandingNavigation
          banner={global?.banner}
          landingNavigationItems={currentLandingNavigationItems}
          {...global?.navigation}
          isDisabled={navigationDisabled}
          theme={navigationTheme}
        />
      ) : (
        <Navigation
          banner={global?.banner}
          {...global?.navigation}
          theme={navigationTheme}
          isDisabled={navigationDisabled}
        />
      )}

      <main
        className={cx(css.Page, {
          [css.isLandingPage]: landingPage?.isLandingPage,
          withoutMarginTop,
          pageHasNoMarginTop,
          isHeroTitleOnly,
        })}>
        {children}
      </main>

      <Footer
        {...global?.footer}
        pageType={document?.type ?? null}
        isLandingPage={landingPage?.isLandingPage}
        theme={isVenusPage ? 'light' : 'dark'}
        topFooterBorderBottomTheme={isVenusPage && GlobalThemeColors.DoveGray}
      />

      <PanelManager />
      <PopInManager />
      <CustomAlerts />
      <VercelSpeedInsights />
    </>
  )
}

Page.defaultProps = {}

export default withPrivate(Page)
