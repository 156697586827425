import classnames from 'classnames/bind'
import Cookies from 'js-cookie'
import qs from 'qs'
import { useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_REST_OF_THE_WORLD_LOCALES,
  NEXT_LOCALE_COOKIE,
} from '~/lib/constants'
import {
  findCountryAttributionFromCountryCode,
  getMarketCountriesValues,
  getMarketLangsAssociationsFromLocales,
} from '~/lib/shopify-market-countries'

import DropdownSelect from '~/components/Form/DropdownSelect'
import { CrossMarketIcon } from '~/components/Icons'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGeolocation } from '~/providers/GeolocationProvider'
import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useMarketContext } from '~/providers/MarketProvider'
import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useClickOutside from '~/hooks/useClickOutside'
import useLocale from '~/hooks/useLocale'
import useMarketCookie from '~/hooks/useMarketCookie'

import getAlternateUrl from '~/utils/alternate-url'
import getCountryNameFromCountryCode from '~/utils/get-country-name-from-country-code'
import { getQueryParams } from '~/utils/get-query-params'
import { getCountryCode } from '~/utils/locales'

import { GENERAL, MARKET } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface MarketPanelProps {
  className?: string
}

function MarketPanel({ className }: MarketPanelProps) {
  const locale = useLocale()
  const t = useTranslate()
  const { metas } = useGlobalData()
  const { countryISO } = useGeolocation()

  const { isLocaleSameAsUserPreferences, userPreferences } = useMarketContext()

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
  })

  const countriesList =
    getMarketCountriesValues({
      locale,
      hasAdditionalOptions: true,
      additionalOptions: [
        {
          label: t(MARKET.BANNER_REST_OF_THE_WORLD),
          value: {
            countryCode: '',
            locales: DEFAULT_REST_OF_THE_WORLD_LOCALES,
          },
        },
      ],
    }) ?? []

  const { setDisplayMarketPanel } = useMarketContext()
  const { setMarketBannerUserPreferences, setMarketPanelDisplayed } =
    useMarketCookie()

  const [selectedCountry, setSelectedCountry] = useState(
    userPreferences?.countryCode && isLocaleSameAsUserPreferences
      ? findCountryAttributionFromCountryCode(
          userPreferences?.countryCode,
          countriesList,
        )
      : countryISO
      ? findCountryAttributionFromCountryCode(countryISO, countriesList)
      : null,
  )

  const langsList = selectedCountry
    ? getMarketLangsAssociationsFromLocales(selectedCountry?.value?.locales)
    : []

  const [selectedLang, setSelectedLang] = useState(
    langsList.length > 0 ? langsList[0] : null,
  )

  const memoizedValues = {
    countries: countriesList,
    langs: langsList,
  }

  const onChangeCountry = (value) => {
    setSelectedCountry(value)
    const langsForSelectedCountry = getMarketLangsAssociationsFromLocales(
      value?.value?.locales,
    )

    if (langsForSelectedCountry.length === 1) {
      setSelectedLang(langsForSelectedCountry[0])
    } else {
      setSelectedLang(
        langsForSelectedCountry.length > 0 ? langsForSelectedCountry[0] : null,
      )
    }
  }

  const onChangeLang = (value) => {
    setSelectedLang(value)
  }

  const { removeCurrent } = usePanel()

  const onClose = () => {
    setDisplayMarketPanel?.(false)
    setMarketPanelDisplayed?.(false)
    removeCurrent()
  }

  const onSubmit = () => {
    let countryCode = selectedCountry?.value?.countryCode ?? null

    if (!countryCode) {
      countryCode = getCountryCode(selectedLang?.value) ?? DEFAULT_COUNTRY_CODE
    }

    setMarketBannerUserPreferences?.({
      countryCode,
      marketName: 'Nina Ricci',
      locale: selectedLang?.value,
    })

    setDisplayMarketPanel?.(false)
    setMarketPanelDisplayed?.(false)

    if (locale !== selectedLang?.value) {
      redirectToLang()
    }

    removeCurrent()
  }

  const redirectToLang = () => {
    const selectedLocale = metas?.langSwitcher?.find(
      (altLng) => altLng?.locale === selectedLang?.value,
    )

    const params = qs.stringify(getQueryParams(), {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })

    const processedParams = `${params ? '?' + params : ''}`
    const pathname = selectedLocale?.url
      ? `${selectedLocale?.url}${processedParams}`
      : getAlternateUrl('/' + selectedLang?.value + processedParams)

    Cookies.set(NEXT_LOCALE_COOKIE, selectedLang?.value)

    window.location.href = pathname
  }

  const bannerRef = useRef(null)

  useClickOutside(
    bannerRef,
    () => {
      setDisplayMarketPanel?.(false)
      setMarketPanelDisplayed?.(false)
    },
    [],
  )

  return (
    <div className={cx(css.banner, className)} ref={bannerRef}>
      <div className={css.header}>
        <p className={cx(css.title, titleStyle)}>
          {t(MARKET.BANNER_VISITING_US_SENTENCE, {
            country: countryISO
              ? getCountryNameFromCountryCode(locale, countryISO)
              : null,
          })}
        </p>

        <button
          className={css.closeButton}
          aria-label={t(GENERAL.CLOSE)}
          onClick={onClose}>
          <CrossMarketIcon />
        </button>
      </div>

      <p className={cx(css.descriptionText, descriptionStyle)}>
        {t(MARKET.BANNER_VISITING_US_DESCRIPTION, {
          country: countryISO
            ? getCountryNameFromCountryCode(locale, countryISO)
            : null,
        })}
      </p>

      <div className={css.contentSection}>
        <DropdownSelect
          className={cx(css.item, css.selectItem, css.countrySelect)}
          onChange={(item) => {
            item?.value && onChangeCountry(item)
          }}
          key={`country_selector`}
          customLabel={t(MARKET.BANNER_CHOOSE_COUNTRY)}
          currentValue={selectedCountry}
          options={memoizedValues.countries}
          isInMarketPanel
          layout="light"
          langSelect
        />

        {selectedCountry && (
          <DropdownSelect
            shouldAutoOpen={memoizedValues?.langs?.length > 1}
            disabled={memoizedValues?.langs?.length <= 1}
            className={cx(css.item, css.selectItem, css.langSelect, {
              hasNoValues: memoizedValues?.langs?.length === 0,
            })}
            onChange={(item) => {
              item?.value && onChangeLang(item)
            }}
            key={`lang_selector`}
            customLabel={t(MARKET.BANNER_CHOOSE_LANG)}
            currentValue={selectedLang}
            options={memoizedValues?.langs ?? []}
            isInMarketPanel
            layout="light"
            langSelect
          />
        )}
      </div>

      <div className={css.ctaContainer}>
        <SquaredCta
          className={css.ctaButton}
          onClick={onSubmit}
          disabled={!selectedCountry || !selectedLang}>
          {t(MARKET.BANNER_SUBMIT)}
        </SquaredCta>
      </div>
    </div>
  )
}

MarketPanel.defaultProps = {}

export default MarketPanel
