import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { CloseIcon, iconFromReactSvg } from '~/components/Abstracts/Icons'
import { FiltersPanel } from '~/components/UI/Filters/Panels/Filtering'
import { SortBy } from '~/components/UI/Filters/Panels/SortBy'

import { useSearchAndDiscoveryContext } from '~/providers/SearchAndDiscoveryProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import { FILTER } from '~/data/dictionary'

import ArrowRight from './arrow-bottom.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export const ArrowBottomIcon = iconFromReactSvg(ArrowRight)

export enum FilterPanelTypes {
  SORT_BY = 'sort_by',
  FILTER_BY = 'filter_by',
}

function Filters({ className }: PropsWithClassName) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const [panelType, setPanelType] = useState<FilterPanelTypes | null>(null)
  const { facets } = useSearchAndDiscoveryContext()

  const filterStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const mobileTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const t = useTranslate()
  const isMobile = useBreakpoint(GlobalBreakpoints.LG)

  const panelComponent = (
    <>
      {panelType === FilterPanelTypes.SORT_BY && (
        <SortBy
          closePanel={() => {
            setPanelType(null)
          }}
        />
      )}
      {panelType === FilterPanelTypes.FILTER_BY && (
        <FiltersPanel
          closePanel={() => {
            setPanelType(null)
          }}
        />
      )}
    </>
  )

  return (
    <>
      {/* Black opaque background */}
      {!isMobile && (
        <AnimatePresence>
          {panelType && (
            <m.div
              className={css.background}
              onClick={() => setPanelType?.(null)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.4 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.4 },
              }}
            />
          )}
        </AnimatePresence>
      )}

      <div className={cx(css.Filters, className)}>
        <div className={cx(css.top, gridStyle)}>
          <div className={css.content}>
            <div className={cx(css.left)}>
              <button
                className={cx(css.filterItem, filterStyle, {
                  isActive:
                    panelType === null ||
                    panelType === FilterPanelTypes.SORT_BY,
                })}
                onClick={() => {
                  setPanelType(
                    panelType === FilterPanelTypes.SORT_BY
                      ? null
                      : FilterPanelTypes.SORT_BY,
                  )
                }}>
                {t(FILTER.SORT_BY)}
                <ArrowBottomIcon
                  className={cx(css.arrowBottom, {
                    isActive: panelType === FilterPanelTypes.SORT_BY,
                  })}
                />
              </button>
              {facets?.length > 0 && (
                <button
                  className={cx(css.filterItem, filterStyle, {
                    isActive:
                      panelType === null ||
                      panelType === FilterPanelTypes.FILTER_BY,
                  })}
                  onClick={() => {
                    setPanelType?.(
                      panelType === FilterPanelTypes.FILTER_BY
                        ? null
                        : FilterPanelTypes.FILTER_BY,
                    )
                  }}>
                  {t(FILTER.FILTER_BY)}
                  <ArrowBottomIcon
                    className={cx(css.arrowBottom, {
                      isActive: panelType === FilterPanelTypes.FILTER_BY,
                    })}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={css.panelContainer}>
        {/* White global panel background */}
        {!isMobile && (
          <AnimatePresence>
            {panelType && (
              <m.div
                className={css.backgroundPanel}
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY: 1,
                  transition: {
                    duration: 0.4,
                    ease: [0.195, 0.59, 0.435, 0.825],
                  },
                }}
                exit={{
                  scaleY: 0,
                  transition: {
                    duration: 0.3,
                    delay: 0.1,
                    ease: [0.46, 0.255, 0.74, 0.48],
                  },
                }}
              />
            )}
          </AnimatePresence>
        )}

        {/* Container with opacity animation for panel */}
        {isMobile ? (
          <>
            {panelType && (
              <div className={css.mobilePanel}>
                <div className={cx(gridStyle, css.topMobileContainer)}>
                  <div className={cx(css.content, css.top)}>
                    <div className={css.close}>
                      <CloseIcon
                        onClick={() => {
                          setPanelType(null)
                        }}
                      />
                    </div>
                    <div className={cx(css.name, mobileTitleStyle)}>
                      {panelType === FilterPanelTypes.FILTER_BY
                        ? t(FILTER.FILTER_BY)
                        : t(FILTER.SORT_BY)}
                    </div>
                  </div>
                </div>

                <div className={cx(gridStyle, css.centerContainer)}>
                  <div className={cx(css.center, css.content)}>
                    {panelComponent}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <AnimatePresence>
            {panelType && (
              <m.div
                className={css.panel}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3, delay: 0.25 },
                }}
                exit={{
                  opacity: [1, 0, 0],
                  transition: {
                    duration: 0.4,
                    times: [0, 0.4, 1],
                  },
                }}>
                {panelComponent}
              </m.div>
            )}
          </AnimatePresence>
        )}
      </div>
    </>
  )
}

export default Filters
