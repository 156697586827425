import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { AccordionPanel } from '@unlikelystudio/react-abstract-components'

import { MinusIcon, PlusIcon } from '~/components/Abstracts/Icons'
import DotCheckbox, { DotCheckboxType } from '~/components/Form/DotCheckbox'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function CheckboxList({
  listIndex,
  type,
  label,
  values,
  forceScrollable = false,
}: {
  listIndex: number
  type: DotCheckboxType
  label: string
  values: { name: string; label: string; value: string }[]
  forceScrollable?: boolean
}) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const t = useTranslate()
  const isMobile = useBreakpoint(GlobalBreakpoints.LG)

  if (!values?.length) return null

  return isMobile ? (
    <AccordionPanel
      ariaLabels={{
        open: t(GENERAL.ACCORDION_OPEN),
        close: t(GENERAL.ACCORDION_CLOSE),
      }}
      index={listIndex}
      className={css.accordionPanel}
      key={`mobile_accordion_panel_${label}`}
      head={(isActive: boolean) => {
        return (
          <div
            className={cx(css.panelHead, titleStyle, {
              isActive,
            })}>
            <div>{label}</div>
            {isActive ? (
              <MinusIcon className={css.icon} fill="#000000" />
            ) : (
              <PlusIcon className={css.icon} fill="#000000" />
            )}
          </div>
        )
      }}
      body={() => {
        return (
          <div className={css.panelBody}>
            {values?.map(({ name, label, value }, index) => {
              return (
                <DotCheckbox
                  key={`mobile_facet_${name}_${value}_${index}`}
                  className={css.columnFilter}
                  id={`${value}_${index}`}
                  label={label}
                  name={name}
                  type={type}
                  value={value}
                />
              )
            })}
          </div>
        )
      }}
    />
  ) : (
    <div className={css.column}>
      {label && <div className={cx(css.columnTitle, titleStyle)}>{label}</div>}

      {values?.length > 0 && (
        <div
          className={cx(css.columnFilters, {
            scrollable: values?.length >= 4 || forceScrollable,
          })}>
          {values?.map(({ name, label, value }, index) => {
            return (
              <DotCheckbox
                key={`facet_${name}_${value}_${index}`}
                className={css.columnFilter}
                id={`${value}_${index}`}
                label={label}
                name={name}
                type={type}
                value={value}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
