import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { squaredCtaNegativeTheme } from '~/lib/negative-theme-colors'

import Cta, { CtaBaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type SquaredCtaTextPreset = GlobalTextPreset.Cta12Haffer

export type SquaredCtaColors =
  | GlobalThemeColors.Black
  | GlobalThemeColors.White
  | GlobalThemeColors.Transparent

export interface SquaredCtaProps extends CtaBaseProps {
  className?: string
  theme?: SquaredCtaColors
  textPreset?: SquaredCtaTextPreset
}

const SquaredCta = ({ className, textPreset, ...rest }: SquaredCtaProps) => {
  const props = {
    css,
    cx,
    negativeColor: squaredCtaNegativeTheme(rest.theme),
  }

  const textStyle = useStyle({
    textPreset,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <Cta
      className={cx(css.SquaredCta, textStyle, className)}
      {...props}
      {...rest}
    />
  )
}

SquaredCta.defaultProps = {
  theme: GlobalThemeColors.Black,
  textPreset: GlobalTextPreset.Cta12Haffer,
  withBorder: true,
}

export default SquaredCta
