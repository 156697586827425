import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'
import { useLandingCurrentNavigationSectionContext } from '~/providers/_Landing/LandingCurrentNavigationSectionProvider'
import { useLandingThemeBackgroundNavigationContext } from '~/providers/_Landing/LandingThemeBackgroundNavigationProvider'
import { useLandingThemeColorNavigationContext } from '~/providers/_Landing/LandingThemeColorNavigationProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type LandingNavigationItemProps = {
  label: RichTextBlocks
  sectionId: string
}[]

export interface LandingNavigationComponentProps {
  className?: string
  items: LandingNavigationItemProps
}

function LandingNavigationComponent({
  className,
  items,
}: LandingNavigationComponentProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const { currentNavigationSection } =
    useLandingCurrentNavigationSectionContext()

  const { themeBackgroundNavigation } =
    useLandingThemeBackgroundNavigationContext()

  const { themeColorNavigation } = useLandingThemeColorNavigationContext()

  const anchorStyle = useStyle({
    textPreset: GlobalTextPreset.Cta11Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  if (items?.length === 0) return null

  return (
    <div className={cx(css.LandingNavigationComponent, gridStyle, className)}>
      <m.div className={cx(css.background, css[themeBackgroundNavigation])} />
      <div className={css.wrapper}>
        {items?.map((item, i) => {
          return (
            <a
              className={cx(
                css.anchor,
                anchorStyle,
                css[themeColorNavigation],
                {
                  [css.active]: currentNavigationSection === item.sectionId,
                },
              )}
              key={`anchor-${i}`}
              href={`#${item.sectionId}`}>
              <RichText render={item.label} />
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default LandingNavigationComponent
