import { SHOPIFY_TYPES } from '~/lib/shopify/constants'

export const PRISMIC_TYPES = {
  ARTICLE: 'article_page',
  BLOG: 'blog_page',
  CATEGORY_ARTICLE: 'category_article',
  ROOT_CATEGORY_FASHION: 'root_category_page_fashion',
  CATEGORY_FASHION: 'category_page_fashion',
  ROOT_CATEGORY_FRAGRANCE: 'root_category_page_fragrance',
  CATEGORY_FRAGRANCE: 'category_page_fragrance',
  ROOT_CATEGORY_FASHION_V2: 'root_category_page_fashion_v2',
  CATEGORY_FASHION_V2: 'category_page_fashion_v2',
  ROOT_CATEGORY_FRAGRANCE_V2: 'root_category_page_fragrance_v2',
  CATEGORY_FRAGRANCE_V2: 'category_page_fragrance_v2',
  CONTACT: 'contact_page',
  ERROR_PAGE: 'error_page',
  FAQ: 'faq_page',
  FAQ_ITEM: 'faq_page_item',
  HOMEPAGE: 'home_page',
  LEGAL: 'legal_page',
  LEGAL_ITEM: 'legal_page_item',
  LIBRARY_UI: 'library_ui',
  LIVESTREAM: 'livestream_page',
  LOOKBOOK: 'lookbook_page',
  MAINTENANCE_PAGE: 'maintenance_page',
  PRODUCT_FASHION: 'product_page_fashion',
  PRODUCT_FRAGRANCE: 'product_page_fragrance',
  PRODUCT_FASHION_V2: 'product_page_fashion_v2',
  PRODUCT_FRAGRANCE_V2: 'product_page_fragrance_v2',
  PRODUCT_V2: 'product_page_v2',
  PRODUCT_SETS: 'product_page_sets',
  PRODUCT_SET: "product_page_set",
  SEARCH: 'search_page',
  UI: 'library_ui',
  UNIVERSAL: 'universal_page',
  LOGIN: 'login_page',
  REGISTER: 'register_page',
  PRIVATE: 'private_page',
  FORGOT_PASSWORD: 'forgot_password_page',
  RESET_PASSWORD: 'reset_password_page',
  ENABLE_ACCOUNT: 'enable_account_page',
  ACCOUNT: 'account_page',
  STORE_LOCATOR: 'store_locator',
  VENUS: 'venus_page',
  NINA_CRUSH: 'nina_crush_page',
} as const

export type TPrismicTypesKey =
  (typeof PRISMIC_TYPES)[keyof typeof PRISMIC_TYPES]

// Const to handle only pages that needed cards inside project
export const PRISMIC_TYPES_WITH_CARD = {
  ARTICLE: 'article_page',
  BLOG: 'blog_page',
  HOMEPAGE: 'home_page',
  FAQ: 'faq_page',
  FAQ_ITEM: 'faq_page_item',
  LIVESTREAM: 'livestream_page',
  LOOKBOOK: 'lookbook_page',
  PRODUCT_SETS: 'product_page_sets',
  UNIVERSAL: 'universal_page',
  CATEGORY_FASHION_V2: 'category_page_fashion_v2',
  CATEGORY_FRAGRANCE_V2: 'category_page_fragrance_v2',
  ROOT_CATEGORY_FASHION_V2: 'root_category_page_fashion_v2',
  ROOT_CATEGORY_FRAGRANCE_V2: 'root_category_page_fragrance_v2',
} as const

export type TPrismicWithCardTypesKey =
  (typeof PRISMIC_TYPES_WITH_CARD)[keyof typeof PRISMIC_TYPES_WITH_CARD]

export const PDP_PAGE_TYPES: TPrismicTypesKey[] = [
  SHOPIFY_TYPES.PRODUCT_FASHION,
  SHOPIFY_TYPES.PRODUCT_FRAGRANCE,
  PRISMIC_TYPES.PRODUCT_SETS,
]
export const PLP_PAGE_TYPES: TPrismicTypesKey[] = [
  PRISMIC_TYPES.ROOT_CATEGORY_FASHION_V2,
  PRISMIC_TYPES.CATEGORY_FASHION_V2,
  PRISMIC_TYPES.ROOT_CATEGORY_FRAGRANCE_V2,
  PRISMIC_TYPES.CATEGORY_FRAGRANCE_V2,
]
/**
 * `isPDP` is a function that returns true if the type is a product type
 * @param {TPrismicTypesKey} type - TPrismicTypesKey
 * @returns A function that takes a type and returns a boolean.
 */
export function isPDP(type: TPrismicTypesKey) {
  return PDP_PAGE_TYPES.includes(type)
}

/**
 * `isPLP` is a function that returns true if the type is a category type
 * @param {TPrismicTypesKey} type - TPrismicTypesKey
 * @returns A function that takes a type and returns a boolean.
 */
export function isPLP(type: TPrismicTypesKey) {
  return PLP_PAGE_TYPES.includes(type)
}

/**
 * It returns true if the type is one of the following:
 *
 * - ACCOUNT
 * - LOGIN
 * - REGISTER
 * - ENABLE_ACCOUNT
 * - FORGOT_PASSWORD
 * - RESET_PASSWORD
 * - FAQ
 * @param {TPrismicTypesKey} type - The type of page you're on.
 * @returns A function that takes a type and returns a boolean.
 */
export function isPageWithoutMarginTop(type: TPrismicTypesKey) {
  return (
    type === PRISMIC_TYPES.ACCOUNT ||
    type === PRISMIC_TYPES.LOGIN ||
    type === PRISMIC_TYPES.REGISTER ||
    type === PRISMIC_TYPES.ENABLE_ACCOUNT ||
    type === PRISMIC_TYPES.FORGOT_PASSWORD ||
    type === PRISMIC_TYPES.RESET_PASSWORD ||
    type === PRISMIC_TYPES.FAQ ||
    type === PRISMIC_TYPES.FAQ_ITEM ||
    type === PRISMIC_TYPES.LEGAL_ITEM ||
    type === PRISMIC_TYPES.MAINTENANCE_PAGE
  )
}

export type TPrismicSlicesKey =
  (typeof PRISMIC_SLICES)[keyof typeof PRISMIC_SLICES]

export const PRISMIC_REPEATABLE_TYPES = [
  PRISMIC_TYPES.ARTICLE,
  PRISMIC_TYPES.LEGAL_ITEM,
  PRISMIC_TYPES.UNIVERSAL,
  PRISMIC_TYPES.CATEGORY_FASHION_V2,
  PRISMIC_TYPES.CATEGORY_FRAGRANCE_V2,
  PRISMIC_TYPES.ROOT_CATEGORY_FASHION_V2,
  PRISMIC_TYPES.ROOT_CATEGORY_FRAGRANCE_V2,
  PRISMIC_TYPES.FAQ_ITEM,
  PRISMIC_TYPES.LEGAL_ITEM,
  PRISMIC_TYPES.LIVESTREAM,
  PRISMIC_TYPES.LOOKBOOK,
  PRISMIC_TYPES.PRODUCT_SETS,
]

export const VENUS_PRISMIC_SLICES = {
  VENUS_HERO_MAIN_SCROLL_SPRITES: 'venus_hero_main_scroll_sprites',
  VENUS_EDITO_HERO_MAIN_VIDEO_OR_IMAGE: 'venus_edito_hero_main_video_or_image',
  VENUS_VIDEO: 'venus_video',
  VENUS_EDITO_IMAGE_WITH_SUB_EDITO: 'venus_edito_image_with_sub_edito',
  VENUS_DOUBLE_IMAGE: 'venus_double_image',
  VENUS_EDITO_MAIN_IMAGE: 'venus_edito_main_image',
  VENUS_EDITO_BACKGROUND_VIDEO: 'venus_edito_background_video',
  VENUS_SPLITTED_TEXT_MAIN_IMAGE: 'venus_splitted_text_main_image',
  VENUS_EDITO_MULTIPLE_IMAGE: 'venus_edito_third_image',
  VENUS_PRODUCT_SLIDER: 'venus_product_slider',
} as const

export const NINA_CRUSH_PRISMIC_SLICES = {
  NINA_CRUSH_PRODUCT_SLIDER: 'nina_crush_product_slider',
  NINA_CRUSH_EDITO_HERO_MAIN_VIDEO_OR_IMAGE: 'nina_crush_edito_hero_main_video_or_image',
  NINA_CRUSH_EDITO_IMAGES_WITH_SUB_EDITO: 'nina_crush_edito_images_with_sub_edito',
  NINA_CRUSH_IMAGE_EDITO_BACKGROUND: 'nina_crush_image_edito_background',
  NINA_CRUSH_HEADER_STORY: 'nina_crush_header_story',
  NINA_CRUSH_FORM_EVENT: 'nina_crush_form_event',
} as const

export const PRISMIC_SLICES = {
  ANNOUNCEMENT: 'announcement',
  BIG_IMAGE_EDITO: 'big_image_edito',
  CATEGORY_GRID_EDITO: 'category_grid_edito',
  COLUMN_EDITO: 'column_edito',
  COVER_IMAGE: 'cover_image',
  DIPTYCH_OR_TRIPTYCH: 'diptych_or_triptych',
  EDITO_COLUMN: 'edito_column',
  EDITO_DYPTIC_IMAGE: 'edito_dyptic_image',
  EDITO_IMAGE: 'edito_image',
  EDITO_IMAGE_TITLE_TEXT: 'edito_image_title_text',
  EDITO_PUSH: 'edito_push',
  EDITO_PUSH_IMAGE_TEXT: 'edito_push_image_text',
  EDITO_TITLE_IMAGE_TEXT: 'edito_title_image_text',
  FORM_EVENT_SLICE: 'form_event_slice',
  GRID_LOOKBOOK_COVER_IMAGE: 'grid_lookbook_cover_image',
  GRID_LOOKBOOK_IMAGES: 'grid_lookbook_images',
  GRID_LOOKBOOK_LINKS: 'grid_lookbook_links',
  GRID_LOOKBOOK_SINGLE_IMAGE: 'grid_lookbook_single_image',
  GRID_LOOKBOOK_SLIDER_MULTIPLE_LAYOUT: 'grid_lookbook_slider_multiple_layout',
  HEADER_STORY: 'header_story',
  HERO_MAIN: 'hero_main',
  HERO_MAIN_TEASING: 'hero_main_teasing',
  HERO_MAIN_TEASING_SCROLL: 'hero_main_teasing_scroll',
  HERO_ONE_LINER_TEXT: 'hero_one_liner_text',
  IMAGE_EDITO_COLUMN: 'image_edito_column',
  IMAGE_TITLE: 'image_title',
  IMMERSIVE_EDITO_HERO: 'immersive_edito_hero',
  IMMERSIVE_EDITO_DYPTIC_IMAGES: 'immersive_edito_dyptic_images',
  IMMERSIVE_EDITO_IMAGES_SLIDER: 'immersive_edito_images_slider',
  IMMERSIVE_EDITO_TITLE_TEXT_CTA: 'immersive_edito_title_text_cta',
  IMMERSIVE_EDITO_HERO_TEASING: 'immersive_edito_hero_teasing',
  MASONRY_IMAGE: 'masonry_image',
  MASONRY_IMAGE_EDITO: 'masonry_image_edito',
  PRODUCTS_GRID: 'products_grid',
  PRODUCTS_GRID_EDITO: 'products_grid_edito',
  PRODUCTS_SLIDER: 'products_slider',
  PUSH_COLLECTION: 'push_collection',
  PUSH_COVER: 'push_cover',
  PUSH_IMAGE: 'push_image',
  PUSH_IMAGE_COVER: 'push_image_cover',
  PUSH_IMAGE_TEXT: 'push_image_text',
  PUSH_PRODUCT: 'push_product',
  PUSH_PRODUCTS_IMAGES: 'push_products_images',
  PUSH_PRODUCTS_SLIDER: 'push_products_slider',
  STORIES_GRID_IMAGE: 'stories_grid_image',
  STORIES_SLIDER: 'stories_slider',
  TESTIMONIAL: 'testimonial',
  VIDEO_PLAYER: 'video_player',
  WYSIWYG: 'wysiwyg',
  PARCEL_LAB_TRACKING_WIDGET: 'parcel_lab_tracking',
  PARCEL_LAB_RETURN_WIDGET: 'parcel_lab_return',
  GIFT_CARD_BALANCE_CHECKER: 'gift_card_balance_checker',
  PRODUCTS_SLIDER_V2: 'products_slider_v2',
  PRODUCTS_GRID_V2: 'products_grid_v2',
  PRODUCTS_GRID_EDITO_V2: 'products_grid_edito_v2',
  PUSH_PRODUCTS_IMAGES_V2: 'push_products_images_v2',
  PUSH_PRODUCT_V2: 'push_product_v2',
  PUSH_PRODUCTS_SLIDER_V2: 'push_products_slider_v2',
  SHOPIFY_QUIZZ: 'shopify_quizz',
  ...VENUS_PRISMIC_SLICES,
  ...NINA_CRUSH_PRISMIC_SLICES
} as const

