import { CartProps } from '~/@types/cart'
import {
  isProductFashion,
  isProductFragrance,
  isProductRealProduct,
  isProductSet,
} from '~/lib/custom-product'
import { PRISMIC_TYPES } from '~/lib/prismic-types'
import {
  SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES,
  SHOPIFY_CUSTOM_ATTRIBUTES,
} from '~/lib/shopify/constants'

/**
 * It returns true if the checkout has a product with a custom attribute of `prismicType` with a value
 * of `product_fashion`
 * @param {CartProps} checkout - CartProps - this is the checkout object that is passed to the
 * component.
 * @returns A boolean
 */
export const hasFashionProductInCart = (checkout: CartProps) => {
  return checkout?.products?.find((product) => {
    return (
      product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
      PRISMIC_TYPES.PRODUCT_FASHION && isProductRealProduct(product)
    )
  })
}

/**
 * It returns true if the checkout has a product with a custom attribute of `prismicType` with a value
 * of `product_fragrance`
 * @param {CartProps} checkout - CartProps - this is the checkout object that is passed to the
 * component
 * @returns A boolean value.
 */
export const hasFragranceProductInCart = (checkout: CartProps) => {
  return checkout?.products?.find((product) => {
    return (
      product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
      PRISMIC_TYPES.PRODUCT_FRAGRANCE && isProductRealProduct(product)
    )
  })
}

/**
 * It returns true if the checkout has a product with a custom attribute of `prismicType` with a value
 * of `product_set`
 * @param {CartProps} checkout - CartProps - this is the checkout object that is passed to the
 * component
 * @returns A boolean value.
 */
export const hasSetProductInCart = (checkout: CartProps) => {

  return checkout?.products?.find((product) => {
    return (
      product?.attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
      PRISMIC_TYPES.PRODUCT_SET && isProductRealProduct(product)
    )
  })
}


/**
 * It takes a checkout object and returns the total price of all fashion products in the checkout
 * @param {CartProps} checkout - CartProps
 * @returns The total price of all fashion products in the checkout.
 */
export function countCheckoutTotalFashion(checkout: CartProps) {
  return checkout?.products?.reduce((acc, curr) => {
    return (acc += isProductFashion(curr)
      ? +curr.priceAmount * curr.quantity
      : 0)
  }, 0)
}

/**
 * It takes a checkout object and returns the total price of all fragrance products in the checkout
 * @param {CartProps} checkout - CartProps
 * @returns The total price of all fragrance products in the checkout.
 */
export function countCheckoutTotalFragrance(checkout: CartProps) {
  return checkout?.products?.reduce((acc, curr) => {
    return (acc += isProductFragrance(curr)
      ? +curr.priceAmount * curr.quantity
      : 0)
  }, 0)
}

/**
 * It takes a checkout object and returns the total price of all sets products in the checkout
 * @param {CartProps} checkout - CartProps
 * @returns The total price of all fragrance products in the checkout.
 */
export function countCheckoutTotalSet(checkout: CartProps) {
  return checkout?.products?.reduce((acc, curr) => {
    return (acc += isProductSet(curr)
      ? +curr.priceAmount * curr.quantity
      : 0)
  }, 0)
}


/**
 * It checks if the checkout has a custom attribute called `packagingFashion` and if it does, it checks
 * if it's value is `true`
 * @param {CartProps} checkout - CartProps - this is the checkout object that is passed to the
 * checkout page.
 * @returns A boolean value.
 */
export const hasFashionAttribute = (checkout: CartProps) => {
  return (
    checkout?.attributes?.[
    SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFashion
    ] === 'true'
  )
}

/**
 * It checks if the checkout object has a custom attribute called `packagingFragrance` and if it does,
 * it checks if it's value is `true`
 * @param {CartProps} checkout - CartProps - this is the checkout object that is passed to the
 * component.
 * @returns A boolean value.
 */
export const hasFragranceAttribute = (checkout: CartProps) => {
  return (
    checkout?.attributes?.[
    SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFragrance
    ] === 'true'
  )
}
