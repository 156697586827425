import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import processEnvPublic from 'unlikely-env/public'
import processEnvSystem from 'unlikely-env/system'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useDeviceType } from '@unlikelystudio/react-hooks'

import { AccountSections } from '~/components/Account'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { usePromotionComponentsProvider } from '~/providers/PromotionComponentsProvider'
import { useTracker } from '~/providers/TrackerProvider'

import { getBusinessUnitFromPrismicType } from '~/hooks/tracking/utils'
import useCurrency from '~/hooks/useCurrency'
import useLanguage from '~/hooks/useLanguage'
import useLocale from '~/hooks/useLocale'

import getAlternateUrl from '~/utils/alternate-url'
import { getQueryParams } from '~/utils/get-query-params'
import lowercaseObj from '~/utils/lowercase-obj'
import normalizeString, { normalizeObj } from '~/utils/normalize-string'
import { isVercelPreview, isVercelProduction } from '~/utils/vercel-env'

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

interface PageViewEventData {
  name?: string
  type?: string
  path?: string
  id?: string | string[]
  title?: string
  subType?: string
}

interface ProductWithDimensions {
  id?: string
  name?: string
  price?: string
  brand?: string
  category?: string
  position?: string
  list?: string
  quantity?: string
  // "<fragrances|fashion>"
  dimension63?: string
  // "<product color>"
  dimension64?: string
  // "<customization type>"
  dimension65?: string
  // "<product discount percentage>"
  dimension66?: string
  // "<product catalog price>"
  dimension67?: string
  // "<product gender>"
  dimension68?: string
  // "<product is customizable>"
  dimension69?: string
  // "<is suggested>"
  dimension70?: string
  // "<product launch date>"
  dimension71?: string
  // "<is sampled>"
  dimension72?: string
  // "<sample type>"
  dimension73?: string
  // "<product subcategory>"
  dimension74?: string
  // "<suggested strategy>"
  dimension75?: string
  // "<product type>"
  dimension76?: string
  // "<product universe>"
  dimension77?: string
  // "<media resources>"
  dimension78?: string
  // "<product discount>"
  dimension79?: string
  // "<product line>
  dimension80?: string
}

interface SimpleEventData {
  event: string
  eventAction: string
  eventCategory: string
  eventLabel: string
  interaction?: any
}

export interface EventPayload {
  eventName?: string
  eventAction?: string
  eventCategory?: string
  eventLabel?: string
  interaction?: any
  ecommerce?: {
    currencyCode?: string
    promoView?: {
      promotions?: {
        id?: string
        name?: string
        creative?: string
        position?: string
      }[]
    }
    promoClick?: {
      promotions?: {
        id?: string
        name?: string
        creative?: string
        position?: string
      }[]
    }
    impressions?: ProductWithDimensions[]

    click?: {
      actionField?: {
        list?: string
      }
      currencyCode?: string
      products?: ProductWithDimensions[]
    }
    detail?: {
      products?: ProductWithDimensions[]
    }
    add?: {
      products?: ProductWithDimensions[]
    }
    remove?: {
      products?: ProductWithDimensions[]
    }
  }
}

export interface TrackingProductData {
  item_id?: string
  id?: string
  name?: string
  price?: string
  brand?: string
  category?: string
  position?: string
  list?: string
  type?: string
  color?: string
  variant?: string
  quantity?: string
  customizationType?: string
  discountPercentage?: string
  catalogPrice?: string
  gender?: string
  isCustomizable?: string
  isSuggested?: string
  launchData?: string
  isSample?: string
  sampleType?: string
  subCategory?: string
  suggestedStrategy?: string
  productType?: string
  universe?: string
  mediaResources?: string
  discount?: string
  line?: string
}

export function sanitizeSpacings(string: string) {
  return string?.split('\n')?.join(' ')
}

export function serializeTrackingProduct({
  id = null,
  name = null,
  price = null,
  brand = null,
  category = null,
  position = null,
  list = null,
  variant = null,
  quantity = null,
  item_id = null,
  ...rest
}: TrackingProductData) {
  return normalizeObj(
    lowercaseObj({
      item_id: sanitizeSpacings(item_id ?? 'none'),
      id: sanitizeSpacings(id ?? 'none'),
      name: sanitizeSpacings(name ?? 'none'),
      price: sanitizeSpacings(price?.toString() ?? 'none'),
      brand: sanitizeSpacings(brand ?? 'none'),
      category: sanitizeSpacings(category ?? 'none'),
      position: sanitizeSpacings(position ?? 'none'),
      list: sanitizeSpacings(list ?? 'none'),
      variant: sanitizeSpacings(variant ?? 'none'),
      quantity: sanitizeSpacings(quantity ?? 'none'),
      dimension63: sanitizeSpacings(rest?.type ?? 'none'),
      dimension64: sanitizeSpacings(rest?.color ?? 'none'),
      dimension65: sanitizeSpacings(rest?.customizationType ?? 'none'),
      dimension66: sanitizeSpacings(rest?.discountPercentage ?? 'none'),
      dimension67: sanitizeSpacings(rest?.catalogPrice ?? 'none'),
      dimension68: sanitizeSpacings(rest?.gender ?? 'none'),
      dimension69: sanitizeSpacings(rest?.isCustomizable ?? 'none'),
      dimension70: sanitizeSpacings(rest?.isSuggested ?? 'none'),
      dimension71: sanitizeSpacings(rest?.launchData ?? 'none'),
      dimension72: sanitizeSpacings(`${rest?.isSample ?? false}`),
      dimension73: sanitizeSpacings(rest?.sampleType ?? 'none'),
      dimension74: sanitizeSpacings(rest?.subCategory ?? 'none'),
      dimension75: sanitizeSpacings(rest?.suggestedStrategy ?? 'none'),
      dimension76: sanitizeSpacings(rest?.productType ?? 'none'),
      dimension77: sanitizeSpacings(rest?.universe ?? 'none'),
      dimension78: sanitizeSpacings(rest?.mediaResources ?? 'none'),
      dimension79: sanitizeSpacings(rest?.discount ?? 'none'),
      dimension80: sanitizeSpacings(rest?.line ?? 'none'),
    }),
  )
}

/* The above code is checking the environment in which the code is running. If it is running in a
Vercel production environment, it returns 'production'. If it is running in a Vercel preview
environment, it checks the value of the 'NEXT_PUBLIC_ENV' environment variable. If the value is
'production', it returns 'staging', otherwise it returns 'development'. If it is not running in a
Vercel environment, it returns 'development'. */
export function getGAEnvironementName() {
  let environement
  if (isVercelProduction) environement = 'production'
  else if (isVercelPreview) {
    if (processEnvPublic('NEXT_PUBLIC_ENV') === 'production')
      environement = 'staging'
    else environement = 'development'
  } else environement = 'development'

  return environement
}

export default function useGTMTracking() {
  const router = useRouter()
  const { document } = useGlobalData()
  const { tracker, pageTitle } = useTracker()
  const { promotionsSlicesNumber } = usePromotionComponentsProvider()

  const [gtmReady, setGtmReady] = useState(false)
  const [gtmReadyV2, setGtmReadyV2] = useState(false)
  const eventsBuffer = useRef([])

  useEffect(() => {
    const onGtmReady = () => {
      setGtmReady(true)
    }

    window.addEventListener('gtmReady', onGtmReady)

    return () => {
      window.removeEventListener('gtmReady', onGtmReady)
    }
  }, [setGtmReady])

  useEffect(() => {
    const onGtmReady = () => {
      setGtmReadyV2(true)
    }

    window.addEventListener('gtmReadyV2', onGtmReady)

    return () => {
      window.removeEventListener('gtmReadyV2', onGtmReady)
    }
  }, [setGtmReadyV2])

  const languageCode = useLanguage()
  const countryCode = useLocale()
  const currencyCode = useCurrency()
  const deviceType = useDeviceType()

  // - Page Variables
  // type
  // name
  // contentLevel1
  // contentLevel2
  // contentLevel3
  // contentLevel4
  // contentLevel5
  // contentLevel6
  // id
  // path
  // title
  // subType

  // - Client variables
  // codeReleaseVersion
  // countryCode
  // currencyCode
  // documentLocation
  // environmentName
  // languageCode
  // promotionPlacements
  // webMode

  // - Constants
  // brand
  // businessUnit
  // hasVideos
  // productLine
  // property
  // universe

  const BRAND_NAME = 'nr'
  const slicedCountryCode = countryCode?.slice(-2, countryCode?.length)

  const clientVariables = {
    countryCode: slicedCountryCode,
    currencyCode,
    environmentName: getGAEnvironementName(),
    codeReleaseVersion:
      processEnvSystem('NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA') ?? 'none',
    languageCode,
    webMode: deviceType,
    promotionPlacements: `${promotionsSlicesNumber}`,
  }

  const pageBasics = {
    ...clientVariables,
    brand: BRAND_NAME,
    businessUnit: getBusinessUnitFromPrismicType(document?.type) ?? 'none',
    hasVideos: 'false',
    productLine: 'none',
    property: 'main',
    universe: 'none',
  }

  const generateContentLevels = (levels) => {
    const levelContentObject = {}
    // Generating an empty array to loop around it later
    const emptyArray = new Array(6).fill(null)

    // Looping around the array to fill the object with the asked values
    emptyArray.forEach((item, index) => {
      // If there's an entry filled
      const foundEntry = levels?.[index] ?? null

      // We fill the object with the value or setting it to none as asked
      levelContentObject[`contentLevel${index + 1}`] =
        foundEntry?.toString()?.replaceAll('-', ' ') ?? 'none'
    })

    return levelContentObject
  }

  const getUIEventData = (payload: EventPayload): SimpleEventData => {
    const interaction =
      (payload?.interaction ? { interaction: payload.interaction } : {}) ?? {}

    return {
      event: payload?.eventName,
      eventAction: normalizeString(payload?.eventAction),
      eventCategory: payload?.eventCategory,
      eventLabel: normalizeString(payload?.eventLabel)?.replaceAll("'", ' '),
      ...interaction,
    }
  }

  const getPageData = (): PageViewEventData => {
    const uid = Array.isArray(router.query.uid)
      ? router.query.uid?.[0]
      : router.query.uid
    const route = router.route
    const realPath = router.asPath

    const basePageData = {
      path: realPath,
      id: uid ?? 'none',
      title: pageTitle,
      subType: 'none',
      documentLocation: getAlternateUrl(`/${countryCode}${realPath}`),
    }

    const targetedUid = Array.isArray(uid) ? uid[uid?.length - 1] : uid
    const processedUid = targetedUid?.replaceAll('-', ' ')

    switch (route) {
      case '/404':
        return lowercaseObj({
          type: 'error',
          name: `${BRAND_NAME}/error/404`,
          ...generateContentLevels([]),
          ...basePageData,
          subType: '404',
        })
      case '/':
        return lowercaseObj({
          type: 'home',
          name: `${BRAND_NAME}/home`,
          ...generateContentLevels([]),
          ...basePageData,
        })
      case '/fashion':
        return lowercaseObj({
          type: 'plp',
          name: `${BRAND_NAME}/plp/fashion`,
          ...generateContentLevels(['fashion']),
          ...basePageData,
        })
      case '/fashion/[uid]':
        return lowercaseObj({
          type: 'plp',
          name: `${BRAND_NAME}/plp/fashion/${uid?.replaceAll('-', ' ')}`,
          ...generateContentLevels(['fashion']),
          ...basePageData,
        })
      case '/fashion/product/[uid]':
        return lowercaseObj({
          type: 'pdp',
          name: `${BRAND_NAME}/pdp/fashion/${uid?.replaceAll('-', ' ')}`,
          ...generateContentLevels(['fashion', null, null, null, null, uid]),
          ...basePageData,
        })
      case '/fragrance':
        return lowercaseObj({
          type: 'plp',
          name: `${BRAND_NAME}/plp/fragrances`,
          ...generateContentLevels(['fragrances']),
          ...basePageData,
        })
      case '/fragrance/[uid]':
        return lowercaseObj({
          type: 'plp',
          name: `${BRAND_NAME}/plp/fragrances/${uid?.replaceAll('-', ' ')}`,
          ...generateContentLevels(['fragrances']),
          ...basePageData,
        })
      case '/fragrance/product/[uid]':
        return lowercaseObj({
          type: 'pdp',
          name: `${BRAND_NAME}/pdp/fragrances/${uid?.replaceAll('-', ' ')}`,
          ...generateContentLevels(['fragrances', null, null, null, null, uid]),
          ...basePageData,
        })
      case '/contact':
        return lowercaseObj({
          type: 'information',
          name: `${BRAND_NAME}/information/contact`,
          ...generateContentLevels([]),
          ...basePageData,
        })
      case '/articles':
        return lowercaseObj({
          type: 'content',
          name: `${BRAND_NAME}/content/stories`,
          ...generateContentLevels(['stories']),
          ...basePageData,
        })
      case '/articles/[uid]':
        return lowercaseObj({
          type: 'content',
          name: `${BRAND_NAME}/content/stories/${processedUid}`,
          ...generateContentLevels(['stories', uid]),
          ...basePageData,
        })
      case '/search':
        // eslint-disable-next-line no-case-declarations
        const q = getQueryParams()?.q

        return lowercaseObj({
          type: 'search results',
          name: `${BRAND_NAME}/search results${q ? `/${q}` : ''}`,
          ...generateContentLevels(q ? [q] : []),
          ...basePageData,
        })
      case '/lookbook/[uid]':
        return lowercaseObj({
          type: 'content',
          name: `${BRAND_NAME}/content/lookbook`,
          ...generateContentLevels(['lookbook']),
          ...basePageData,
        })
      case '/livestream/[uid]':
        return lowercaseObj({
          type: 'content',
          name: `${BRAND_NAME}/content/livestream`,
          ...generateContentLevels(['livestream']),
          ...basePageData,
        })
      case '/faq':
        return lowercaseObj({
          type: 'information',
          name: `${BRAND_NAME}/information/faq hub`,
          ...generateContentLevels(['faq hub']),
          ...basePageData,
        })
      case '/faq/[uid]':
        return lowercaseObj({
          type: 'information',
          name: `${BRAND_NAME}/information/faq ${processedUid}`,
          ...generateContentLevels([`faq ${uid}`]),
          ...basePageData,
        })
      case '/legal/[uid]':
        return lowercaseObj({
          type: 'information',
          name: `${BRAND_NAME}/information/${processedUid}`,
          ...generateContentLevels([`${uid}`]),
          ...basePageData,
        })
      case '/store-locator':
        return lowercaseObj({
          type: 'storelocator',
          name: `${BRAND_NAME}/storelocator`,
          ...generateContentLevels([]),
          ...basePageData,
        })
      case '/login':
        return lowercaseObj({
          type: 'account',
          name: `${BRAND_NAME}/account/login`,
          ...generateContentLevels(['login']),
          ...basePageData,
        })
      case '/register':
        return lowercaseObj({
          type: 'account',
          name: `${BRAND_NAME}/account/register`,
          ...generateContentLevels(['register']),
          ...basePageData,
        })
      case '/account': {
        const section = (
          Array.isArray(router.query.section)
            ? router?.query?.section?.[0] ?? AccountSections.Orders
            : router?.query?.section ?? AccountSections.Orders
        ).replaceAll('_', '-')
        return lowercaseObj({
          type: 'account',
          name: `${BRAND_NAME}/account/${section}`,
          ...generateContentLevels([section]),
          ...basePageData,
        })
      }
      default:
        // Universals and fallback case
        return lowercaseObj({
          type: 'content',
          name: `${BRAND_NAME}/content/universal/${processedUid}`,
          ...generateContentLevels(['universal', uid]),
          ...basePageData,
        })
    }
  }

  const pushToDataLayer = (event: any) => {
    // Uncomment to debug
    // console.log(event)
    eventsBuffer.current.push(event)

    if (gtmReady) {
      window.dataLayer.push(event)
    }

    // Uncomment to debug
    // console.log({ eventsBuffer: eventsBuffer.current })
    // console.log({ dataLayer: window.dataLayer })
  }

  useEffect(() => {
    if (gtmReady) {
      eventsBuffer.current.forEach((event) => {
        console.log('pushing to dataLayer from gtmReady', event)
        window.dataLayer.push(event)
      })
    }
  }, [gtmReady])

  useEffect(() => {
    if (gtmReadyV2) {
      eventsBuffer.current.forEach((event) => {
        console.log('pushing to dataLayer from gtmReadyv2', event)
        window.dataLayer.push(event)
      })
    }
  }, [gtmReadyV2])

  const eventReducer = (
    eventType: TRACKING_EVENTS,
    eventPayload: EventPayload,
  ) => {
    switch (eventType) {
      case TRACKING_EVENTS.PAGE_VIEW:
        pushToDataLayer({
          event: 'sitewide',
          page: {
            ...pageBasics,
            ...getPageData(),
          },
        })
        break
      case TRACKING_EVENTS.CONTACT_THANK_YOU_PAGE_VIEW:
        pushToDataLayer({
          event: 'sitewide',
          page: {
            title: pageTitle,
            path: '/contact',
            id: 'none',
            type: 'information',
            name: `${BRAND_NAME}/information/contact thankyou`,
            ...pageBasics,
            ...generateContentLevels(['contact thankyou']),
          },
        })
        break
      case TRACKING_EVENTS.TAGGED_EVENT:
      case TRACKING_EVENTS.INTERACTION_TAGGED_EVENT:
        pushToDataLayer(getUIEventData(eventPayload))
        break
      case TRACKING_EVENTS.GO_TO_CHECKOUT:
        pushToDataLayer({
          event: 'eventGeneric',
          eventCategory: 'ecommerce',
          eventAction: 'go to checkout',
          eventLabel: undefined,
        })
        break
      case TRACKING_EVENTS.LOGOUT:
        pushToDataLayer({
          event: 'logout',
          eventCategory: 'user action',
          eventAction: 'logout',
          eventLabel: undefined,
        })
        break
      case TRACKING_EVENTS.LOGIN_ERROR:
        pushToDataLayer({
          event: 'loginError',
          eventCategory: 'form',
          eventAction: 'login',
          eventLabel: eventPayload.eventLabel?.toLocaleLowerCase() ?? 'none',
        })
        break
      case TRACKING_EVENTS.LOGIN_SUCCESS:
        pushToDataLayer({
          event: 'loginSuccess',
          eventCategory: 'form',
          eventAction: 'login',
          eventLabel: 'ok',
        })
        break
      case TRACKING_EVENTS.SIGN_UP_ERROR:
        pushToDataLayer({
          event: 'signUpError',
          eventCategory: 'signup',
          eventAction: undefined,
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
        })
        break
      case TRACKING_EVENTS.SIGN_UP_SUCCESS:
        pushToDataLayer({
          event: 'signUpSuccess',
          eventCategory: 'signup',
          eventAction: undefined,
          eventLabel: 'ok',
        })
        break
      case TRACKING_EVENTS.PRODUCT_IMPRESSION:
        pushToDataLayer({
          event: 'productImpression',
          eventCategory: 'ecommerce',
          eventAction: 'product impression',
          eventLabel: undefined,
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.PRODUCT_CLICK:
        pushToDataLayer({
          event: 'productClick',
          eventCategory: 'ecommerce',
          eventAction: 'product click',
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.PRODUCT_DETAIL:
        pushToDataLayer({
          event: 'productDetail',
          eventCategory: 'ecommerce',
          eventAction: 'pdp',
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.OPEN_CART:
        pushToDataLayer({
          event: 'displayCart',
          eventCategory: 'ecommerce',
          eventAction: 'cart',
          eventLabel: undefined,
        })
        break
      case TRACKING_EVENTS.ADD_TO_CART:
        pushToDataLayer({
          event: 'addToCart',
          eventCategory: 'ecommerce',
          eventAction: 'add to cart',
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.ADD_SAMPLES:
        pushToDataLayer({
          event: 'addSamples',
          eventCategory: 'samples',
          eventAction: 'fixed',
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
        })
        break

      case TRACKING_EVENTS.CHOOSE_SAMPLES:
        pushToDataLayer({
          event: 'chooseSamples',
          eventCategory: 'samples',
          eventAction: 'choose sample',
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
        })
        break
      case TRACKING_EVENTS.REMOVE_FROM_CART:
        pushToDataLayer({
          event: 'removeFromCart',
          eventCategory: 'ecommerce',
          eventAction: 'remove from cart',
          eventLabel: eventPayload?.eventLabel?.toLocaleLowerCase() ?? 'none',
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.PROMOTION_IMPRESSION:
        pushToDataLayer({
          event: 'promoView',
          eventCategory: 'ecommerce',
          eventAction: 'promotion impression',
          eventLabel: undefined,
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.PROMOTION_CLICK:
        pushToDataLayer({
          event: 'promotionClick',
          eventCategory: 'ecommerce',
          eventAction: 'promotion click',
          eventLabel: normalizeString(eventPayload?.eventLabel) ?? 'none',
          ecommerce: eventPayload?.ecommerce ?? {},
        })
        break
      case TRACKING_EVENTS.MENU_HEADER_LINKS:
        pushToDataLayer({
          event: 'menuHeaderLinks',
          eventAction: 'header',
          eventCategory: 'menu',
          eventLabel: `image ${eventPayload?.eventLabel
            ? normalizeString(eventPayload?.eventLabel)
            : ''
            }`,
        })
        break
      case TRACKING_EVENTS.FILTERS_FILTER:
        pushToDataLayer({
          event: 'plp_filter',
          eventCategory: 'filter',
          eventAction: 'filter',
          eventLabel: normalizeString(eventPayload?.eventLabel) ?? 'none',
        })
        break
      case TRACKING_EVENTS.FILTERS_SORT:
        pushToDataLayer({
          event: 'plp_filter',
          eventCategory: 'filter',
          eventAction: 'sort',
          eventLabel: normalizeString(eventPayload?.eventLabel) ?? 'none',
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    tracker.on('*', eventReducer)
    return () => {
      tracker.off('*', eventReducer)
    }
  })
}
