import { createContext, useContext, useMemo, useState } from 'react'

export const LandingCurrentNavigationSectionContext = createContext<ValueType>(
  {},
)

interface LandingCurrentNavigationSectionProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface ValueType {
  currentNavigationSection?: string
  setCurrentNavigationSection?: (value: string) => void
}

export default function LandingCurrentNavigationSectionProvider({
  children,
}: LandingCurrentNavigationSectionProviderProps) {
  const [currentNavigationSection, setCurrentNavigationSection] =
    useState<string>(null)

  const processedValue: ValueType = useMemo(
    () => ({
      currentNavigationSection,
      setCurrentNavigationSection,
    }),
    [currentNavigationSection, setCurrentNavigationSection],
  )

  return (
    <LandingCurrentNavigationSectionContext.Provider value={processedValue}>
      {children}
    </LandingCurrentNavigationSectionContext.Provider>
  )
}

export function useLandingCurrentNavigationSectionContext() {
  return useContext(LandingCurrentNavigationSectionContext)
}
