import { createContext, useContext, useMemo, useState } from 'react'

import { LandingSliceWrapperProps } from '~/components/UI/_Landing/LandingSliceWrapper'

export const LandingThemeBackgroundNavigationContext =
  createContext<LandingNavigationThemeValueType>({})

interface LandingThemeBackgroundNavigationProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface LandingNavigationThemeValueType {
  themeBackgroundNavigation?: LandingSliceWrapperProps['themeBackgroundNavigation']
  setThemeBackgroundNavigation?: (
    value: LandingSliceWrapperProps['themeBackgroundNavigation'],
  ) => void
}

export default function LandingThemeBackgroundNavigationProvider({
  children,
}: LandingThemeBackgroundNavigationProviderProps) {
  const [themeBackgroundNavigation, setThemeBackgroundNavigation] =
    useState<LandingSliceWrapperProps['themeBackgroundNavigation']>('none')

  const processedValue: LandingNavigationThemeValueType = useMemo(
    () => ({
      themeBackgroundNavigation,
      setThemeBackgroundNavigation,
    }),
    [themeBackgroundNavigation, setThemeBackgroundNavigation],
  )

  return (
    <LandingThemeBackgroundNavigationContext.Provider value={processedValue}>
      {children}
    </LandingThemeBackgroundNavigationContext.Provider>
  )
}

export function useLandingThemeBackgroundNavigationContext() {
  return useContext(LandingThemeBackgroundNavigationContext)
}
