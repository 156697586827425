import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useLayoutEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'

import Navigation from '~/components/Navigation/Navigation'
import { NavigationProps } from '~/components/Navigation/Navigation/types'
import LandingNavigationComponent, {
  LandingNavigationItemProps,
} from '~/components/UI/_Landing/LandingNavigationComponent'

import { useLandingThemeBackgroundNavigationContext } from '~/providers/_Landing/LandingThemeBackgroundNavigationProvider'
import { useLandingThemeColorNavigationContext } from '~/providers/_Landing/LandingThemeColorNavigationProvider'

import useScrollDirection from '~/hooks/useScrollDirection'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type TLandingNavigation = {
  landingNavigationItems: LandingNavigationItemProps
} & NavigationProps

const DURATION = 0.5

function LandingNavigation({
  className,
  banner,
  isDisabled,
  landingNavigationItems,
  theme,
  ...rest
}: TLandingNavigation) {
  const scrollDirection = useScrollDirection()
  const [landingNavigationRef, { height: landingNavigationHeightMeasure }] =
    useMeasure()
  const { themeBackgroundNavigation } =
    useLandingThemeBackgroundNavigationContext()

  const { themeColorNavigation } = useLandingThemeColorNavigationContext()

  const [mainNavigationDisplay, setMainNavigationDisplay] = useState(true)
  const [landingNavigation, setLandingNavigation] = useState(false)

  const landingNavigationHeight = !landingNavigationHeightMeasure
    ? 71
    : landingNavigationHeightMeasure

  useLayoutEffect(() => {
    if (scrollDirection === 'up') {
      setMainNavigationDisplay(true)
      setLandingNavigation(false)
    }
    if (scrollDirection === 'down') {
      setMainNavigationDisplay(false)
      setLandingNavigation(true)
    }
  }, [scrollDirection])

  const mainNavigationAnimation = {
    initial: {
      y: -landingNavigationHeight,
      opacity: 0,
    },
    animate: {
      y: mainNavigationDisplay ? 0 : -landingNavigationHeight,
      opacity: mainNavigationDisplay ? 1 : 0,
      transition: {
        duration: DURATION,
      },
    },
    exit: {
      y: -landingNavigationHeight,
      opacity: 0,
    },
  }

  const landingNavigationAnimation = {
    initial: {
      y: -landingNavigationHeight,
      opacity: 0,
    },
    animate: {
      y: landingNavigation ? 0 : -landingNavigationHeight,
      opacity: landingNavigation ? 1 : 0,
      transition: {
        duration: DURATION,
      },
    },
    exit: {
      y: -landingNavigationHeight,
      opacity: 0,
    },
  }

  const getThemeColor = () => {
    switch (themeColorNavigation) {
      case 'light':
        return GlobalThemeColors.White

      case 'dark':
        return GlobalThemeColors.Black

      default:
        return theme
    }
  }

  return (
    <div className={cx(css.LandingNavigation, className)}>
      <AnimatePresence>
        {mainNavigationDisplay && (
          <m.div
            className={cx(css.navigation)}
            initial={mainNavigationAnimation.initial}
            animate={mainNavigationAnimation.animate}
            exit={mainNavigationAnimation.exit}>
            <Navigation
              className={cx(css.mainNavigation, css[themeBackgroundNavigation])}
              banner={banner}
              {...rest}
              isDisabled={isDisabled}
              hasAnimation={false}
              theme={getThemeColor()}
            />
          </m.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {landingNavigation && (
          <m.div
            ref={landingNavigationRef}
            className={css.navigation}
            initial={landingNavigationAnimation.initial}
            animate={landingNavigationAnimation.animate}
            exit={landingNavigationAnimation.exit}>
            <LandingNavigationComponent items={landingNavigationItems} />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default LandingNavigation
