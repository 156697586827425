import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface Slice404Props {
  className?: string
  title?: RichTextBlocks
  text?: RichTextBlocks
  cta?: SquaredCtaProps
}

function Slice404({ className, title, text, cta }: Slice404Props) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title22_30Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.Black,
  })

  return (
    <section className={cx(css.Slice404, className)}>
      <div className={cx(css.grid, gridStyle)}>
        <div className={css.texts}>
          <RichText className={cx(css.title, titleStyle)} render={title} />
          <RichText className={cx(css.text, textStyle)} render={text} />
          {cta && <SquaredCta className={css.cta} {...cta} />}
        </div>
      </div>
    </section>
  )
}

Slice404.defaultProps = {}

export default Slice404
