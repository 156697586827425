// PUBLIC
const PUBLIC = {
  NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR:
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
  NEXT_PUBLIC_COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA,
  NEXT_PUBLIC_PRISMIC_REPO_NAME: process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME,
  NEXT_PUBLIC_COOKIE_PRO_ID: process.env.NEXT_PUBLIC_COOKIE_PRO_ID,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_SHOPIFY_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_TOKEN,
  NEXT_PUBLIC_DISABLE_ECOM: process.env.NEXT_PUBLIC_DISABLE_ECOM,
  NEXT_PUBLIC_ALL_ECOM: process.env.NEXT_PUBLIC_ALL_ECOM,
  NEXT_PUBLIC_COOKIES_SDK: process.env.NEXT_PUBLIC_COOKIES_SDK
} as const

export type TPUBLIC = keyof typeof PUBLIC

/**
 * It takes a variable name as a string, and returns the value of that variable from the PUBLIC object
 * @param {TPUBLIC} variable - TPUBLIC
 * @returns The value of the variable in the PUBLIC object.
 */
export default function processEnvPublic(variable: TPUBLIC) {
  const envVar = PUBLIC[variable]

  if (!envVar) {
    throw new Error(`[${variable}] => Not founded public variable`)
  }

  return envVar
}
