import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { inlineCtaNegativeTheme } from '~/lib/negative-theme-colors'

import { Line, LineProps } from '@unlikelystudio/react-abstract-components'

import Cta, { CtaBaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type InlineCtaTextStyling =
  | GlobalTextStyling.UpperCase
  | GlobalTextStyling.LetterSpacing2

export type InlineCtaTextPreset =
  | GlobalTextPreset.Cta11Haffer
  | GlobalTextPreset.Cta12Haffer

export type InlineCtaColors =
  | GlobalThemeColors.Black
  | GlobalThemeColors.White
  | GlobalThemeColors.DoveGray
  | GlobalThemeColors.MineShaft
  | GlobalThemeColors.Alto

export interface InlineCtaProps extends CtaBaseProps {
  className?: string
  theme?: InlineCtaColors
  textPreset?: InlineCtaTextPreset
  textStyling?: InlineCtaTextStyling | InlineCtaTextStyling[]
  lineProps?: LineProps
  active?: boolean
  hasLine?: boolean
  withoutTextStyle?: boolean
  staticLine?: boolean
}

function InlineCta({
  className,
  children,
  lineProps,
  textPreset,
  textStyling,
  staticLine = false,
  hasLine = true,
  withoutTextStyle,
  ...rest
}: InlineCtaProps) {
  const props = {
    css,
    cx,
    negativeColor: inlineCtaNegativeTheme(rest.theme),
  }

  const textStyle = useStyle({
    textPreset,
    color: rest.theme,
    textStyling: textStyling ?? null,
  })

  return (
    <Cta
      className={cx(
        className,
        !withoutTextStyle && textStyle,
        css.InlineCta,
        css?.[rest.theme],
        {
          hasLine,
          isStaticLine: staticLine && hasLine,
        },
      )}
      {...props}
      {...rest}>
      {({ isHover }) => (
        <span className={css.contentContainer}>
          {hasLine && !staticLine && (
            <Line
              className={css.line}
              theme={rest.theme}
              thickness={lineProps.thickness}
              isVisible={
                lineProps.initialLineState === 'underlined' ? !isHover : isHover
              }
              {...lineProps}
            />
          )}
          {children}
        </span>
      )}
    </Cta>
  )
}

InlineCta.defaultProps = {
  textPreset: GlobalTextPreset.Cta12Haffer,
  theme: GlobalThemeColors.Black,
  lineProps: {
    acumulator: 1,
    thickness: 1,
    initialLineState: 'underlined',
  },
  hasLine: true,
  withoutTextStyle: false,
}

export default InlineCta
