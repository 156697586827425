import { ALERT_PRESETS } from '~/@types/alert'
import { GlobalGender } from '~/@types/gender'

export const GENERAL = {
  ARTICLE: 'general.article',
  STORE_LOCATOR: 'general.store_locator',
  BLOG: 'general.blog',
  BURGER_ARIA_LABEL: 'general.burger_aria_label',
  CART: 'general.cart',
  CATEGORY_FASHION_PAGE: 'general.category_fashion_page',
  CATEGORY_FRAGRANCE_PAGE: 'general.category_fragrance_page',
  CONTACT: 'general.contact',
  DISCOVER_MORE: 'general.discover_more',
  DISCOVER: 'general.discover',
  ERROR: 'general.error',
  FAQ_PAGE: 'general.faq_page',
  FAQ_PAGE_ITEM: 'general.faq_page_item',
  HOME_PAGE: 'general.homepage',
  LEGAL: 'general.legal',
  LEGAL_ITEM: 'general.legal_item',
  LOGO_TITLE: 'general.logo_title',
  MENU: 'general.menu',
  PRODUCT_FASHION_PAGE: 'general.product_fashion_page',
  PRODUCT_FRAGRANCE_PAGE: 'general.product_fragrance_page',
  PRODUCT_SET_PAGE: 'general.product_set_page',
  SEARCH: 'general.search',
  RANKING_DISCOVER: 'general.ranking_discover',
  FULLSCREEN_PREVIOUS: 'general.fullscreen_previous',
  FULLSCREEN_NEXT: 'general.fullscreen_next',
  FULLSCREEN_CLOSE: 'general.fullscreen_close',
  CLOSE: 'general.close',
  LOAD_MORE: 'general.load_more',
  COOKIE_PRO_TABLE_TITLE: 'general.cookie_pro_table_title',
  COOKIE_EDIT_CONSENT: 'cookies.edit_pro_consent',
  FREE: 'general.free',
  SLIDER_BUTTON_LEFT: 'general.slider_button_left',
  SLIDER_BUTTON_RIGHT: 'general.slider_button_right',
  ACCORDION_OPEN: 'general.accordion_open',
  ACCORDION_CLOSE: 'general.accordion_close',
}

export const CONTACT = {
  FASHION: 'contact.fashion',
  FRAGRANCE: 'contact.fragrance',
  CLIENT_SERVICE: 'contact.client_service',
  PRESS_SERVICE: 'contact.press_service',
  OTHER: 'contact.other',
  CATEGORY: 'contact.category',
  SUBJECT: 'contact.subject',
}

export const BLOG = {
  LOAD_MORE: 'blog.load_more',
  FILTER_AVAILABILITY: 'blog.count_availability',
  FILTER_AVAILABILITIES: 'blog.count_availabilities',
}

export const GENDER = {
  [GlobalGender.MALE]: 'gender.male',
  [GlobalGender.FEMALE]: 'gender.female',
  [GlobalGender.OTHER]: 'gender.other',
}

export const PRODUCT = {
  INTENSITY: 'product.intensity',
  SHOP_NOW: 'product.shop_now',
  NEAREST_STORE: 'product.nearest_store',
  SELECT_YOUR_SIZE: 'product.select_your_size',
  FROM: 'product.from',
  SIZE: 'product.size',
  SETS_SAVE: 'product.sets_save',
  FREE: 'product.free',
  DISCOUNT: 'product.discount',
  ACCORDION_DESCRIPTION_TITLE: 'product.accordion_description_title',
  ACCORDION_COMPOSITION_DETAILS_TITLE:
    'product.accordion_composition_details_title',
  ACCORDION_EXPEDITION_RETOURS_TITLE:
    'product.accordion_expedition_retours_title',
  ACCORDION_CONSEILS_TITLE: 'product.accordion_conseils_title',
  ACCORDION_COMPOSITION_TITLE: 'product.accordion_composition_title',
  SEE_DETAILS: 'product.see_details',
}

export const FAQ = {
  VIEW_ALL: 'faq.view_all',
}

export const FORM = {
  ACCEPT_MARKETING: 'form.accept_marketing',
  ACCEPT_TERMS: 'form.accept_terms',
  ADDRESS_NAME: 'form.address_name',
  BIRTH_DATE: 'form.birth_date',
  CATEGORY: 'form.category',
  CITY: 'form.city',
  CONFIRM_PASSWORD: 'form.confirm_password',
  COUNTRY: 'form.country',
  DEFAULT_ADDRESS: 'form.default_address',
  EMAIL: 'form.email',
  FEMALE: 'form.female',
  FIRST_NAME: 'form.first_name',
  INFOS_ADDRESS: 'form.infos_address',
  LAST_NAME: 'form.last_name',
  LOGIN: 'form.login',
  MALE: 'form.male',
  MANDATORY: 'form.mandatory',
  MESSAGE: 'form.message',
  NAME: 'form.name',
  NEW_PASSWORD: 'form.new_password',
  NEWSLETTER_EMAIL: 'form.newsletter_email',
  NEWSLETTER_SUCCESS: 'form.newsletter_success',
  PASSWORD: 'form.password',
  PASSWORD_RESTRICTION: 'form.password_restriction',
  REGION: 'form.region',
  REGISTER: 'form.register',
  REPEAT_PASSWORD: 'form.repeat_password',
  REPEAT_NEW_PASSWORD: 'form.repeat_password',
  REPEAT_PASSWORD_LABEL: 'form.repeat_password_label',
  REQUIRED_ACCEPTS_PRIVACY_POLICY: 'form.required_accepts_privacy_policy',
  SAVE: 'form.save',
  SEARCH: 'form.search',
  SEND: 'form.send',
  SUBSCRIBE: 'form.subscribe',
  STATE: 'form.state',
  STREET_NAME: 'form.street_name',
  SUBJECT: 'form.subject',
  SUBMIT: 'form.submit',
  TEL: 'form.tel',
  TITLE: 'form.title',
  ZIP_CODE: 'form.zip_code',
  RESET_PASSWORD: 'form.reset_password',
  YOUR_EMAIL: 'form.your_email',
  QUANTITY_INCREASE: 'form.quantity_increase',
  QUANTITY_DECREASE: 'form.quantity_decrease',
  NINA_CRUSH_FORM_EVENT_SUBSCRIBE: 'form.nina_crush_form_event_subscribe',
}

export const ACCOUNT = {
  ADD_ADDESSS: 'account.add_address',
  CHANGE_PASSWORD: 'account.change_password',
  CLOSE: 'account.close',
  DATE: 'account.date',
  DEFAULT_ADDRESS: 'account.default_address',
  DELETE: 'account.delete',
  DELETE_ACCOUNT: 'account.delete_account',
  DELIVERY: 'account.delivery',
  DETAILS: 'account.details',
  EDIT: 'account.edit',
  ORDER_NUMBER: 'account.order_number',
  QUANTITY: 'account.quantity',
  RETURN_REPLACE: 'account.return_replace',
  TOTAL: 'account.total',
  TRACK_PACKAGE: 'account.track_package',
  VIEW: 'account.view',
  REGISTER: 'account.register',
  LOGIN: 'account.login',
  LOGOUT: 'account.logout',
  HELLO: 'account.hello',
  NEW_ADDRESS: 'account.new_address',
  NO_ORDERS: 'account.no_orders',
  NO_ADDRESSES: 'account.no_addresses',
}

export const CTA = {
  ADD_TO_CART: 'cta.add_to_cart',
  CLOSE: 'cta.close',
  OUT_OF_STOCK: 'cta.out_of_stock',
  REMOVE_FROM_CART: 'cta.remove_from_cart',
  CONFIRM_CART: 'cta.confirm_cart',
}

export const CART = {
  PRICE_TOTAL: 'cart.price_total',
  QUANTITY: 'cart.quantity',
  SIZE: 'cart.size',
  COLOR: 'cart.color',
  OUT_OF_STOCK: 'cart.out_of_stock',
}

export const PANEL = {
  CLOSE: 'panel.close',
}

export const SETS_VARIANTS = {
  CTA: 'sets_variants.cta',
}

export const FILTER = {
  ALL: 'filter.all',
  CLEAR_ALL: 'filter.clear_all',
  CATEGORIES: 'filter.categories',
  COUNT_AVAILABILITY: 'filter.count_availability',
  COUNT_AVAILABILITIES: 'filter.count_availabilities',
  FILTER_BY: 'filter.filter_by',
  NO_RESULT: 'filter.no_result',
  SORT_BY: 'filter.sort_by',
  VALIDATE: 'filter.validate',
  FACET: (type: string) => `filter.facet.${type?.toLocaleLowerCase()}`,
}

export const ERROR = {}

export const SEARCH = {
  COUNT_AVAILABILITY: 'search.count_availability',
  COUNT_AVAILABILITIES: 'search.count_availabilities',
  EMPTY_RESULTS: 'search.search_no_results',
  LOAD_MORE: 'search.search_load_more',
  FRAGRANCE_RESULTS: 'search.fragrance_results',
  FASHION_RESULTS: 'search.fashion_results',
  BEST_SELLERS_RESULTS: 'search.best_sellers_results',
}

export const ORDER = {
  DELIVERY_TRACK: 'order.delivery_track',
  DELIVERY_AWAITING_SHIPMENT: 'order.delivery_awaiting_shipment',
  FREE_DELIVERY: 'order.free_delivery',

  /* Shopify order status */
  STATUS_UNFULFILLED: 'order.status_unfulfilled',
  STATUS_PARTIALLYFULFILLED: 'order.status_partially_fulfilled',
  STATUS_FULFILLED: 'order.status_fulfilled',
  STATUS_RESTOCKED: 'order.status_restocked',
  STATUS_PENDINGFULFILLMENT: 'order.status_pending_fulfillment',
  STATUS_OPEN: 'order.status_open',
  STATUS_INPROGRESS: 'order.status_in_progress',
  STATUS_ONHOLD: 'order.status_on_hold',
  STATUS_SCHEDULED: 'order.status_scheduled',

  /* Shopify order payment status */
  PAYMENT_PENDING: 'order.payment_pending',
  PAYMENT_AUTHORIZED: 'order.payment_AUTHORIZED',
  PAYMENT_PARTIALLYPAID: 'order.payment_partially_paid',
  PAYMENT_PARTIALLYREFUNDED: 'order.payment_partially_refunded',
  PAYMENT_VOIDED: 'order.payment_voided',
  PAYMENT_PAID: 'order.payment_paid',
  PAYMENT_REFUNDED: 'order.payment_refunded',
}

export const ALERT = {
  ERROR_TITLE: 'alert.error_title',
  SUCCESS_TITLE: 'alert.success_title',
  CLOSE: 'alert.close',
  STOCK_UNAVAILABLE: 'alert.stock',
  NO_MORE_PRODUCTS_AVAILABLE: 'alert.no_more_products_available',
}

ALERT_PRESETS?.forEach(
  (alert) => (ALERT[alert] = `alert.${alert.toLowerCase()}`),
)

export const LANG_SWITCHER = {
  LANGUAGE: (lang = 'en') => `language.${lang?.toLocaleLowerCase()}`,
  LANGUAGE_SYMBOL: (lang = 'en') =>
    `language_symbol.${lang?.toLocaleLowerCase()}`,
}

export const BREADCRUMB = {}

export const MARKET = {
  BANNER_TEXT: 'market.banner_text',
  BANNER_VISITING_US_SENTENCE: 'market.banner_visiting_us_sentence',
  BANNER_VISITING_US_DESCRIPTION: 'market.banner_visiting_us_description',
  BANNER_SUBMIT: 'market.banner_submit',
  BANNER_COUNTRIES: 'market.banner_countries',
  BANNER_LANGS: 'market.banner_langs',
  BANNER_CHOOSE_COUNTRY: 'market.banner_choose_country',
  BANNER_CHOOSE_LANG: 'market.banner_choose_lang',
  BANNER_REST_OF_THE_WORLD: 'market.banner_rest_of_the_world',
}

export const SIZE_GUIDE = {
  TITLE: 'size_guide.title',
  DESCRIPTION: 'size_guide.description',
  DESCRIPTION_SHOES: 'size_guide.description_shoes',
  NOTICE: 'size_guide.notice',
  SIZE_SWITCHER_TITLE: 'size_guide.size_switcher_title',
  TABLE_GUIDE_TITLE: 'size_guide.table_guide_title',
  UNIVERSAL_TABLE_TITLE: 'size_guide.universal_table_title',
  SIZE: 'size_guide.size',
  CHEST: 'size_guide.chest',
  SHOULDER: 'size_guide.shoulder',
  WAIST: 'size_guide.waist',
  HIPS: 'size_guide.hips',
  RING: 'size_guide.ring',
  SHOES: 'size_guide.shoes',
}

export const SIZE_GUIDE_TYPE = {
  DEFAULT: 'default',
  SIZE_CONVERSION: 'size_conversion',
}

export const GIFT_CARD = {
  ENTER_CARD_NR: 'gift_card.enter_card_nr',
  CHECK_BALANCE: 'gift_card.check_balance',
  CARD_NUMBER: 'gift_card.card_number',
  EXPIRES_ON: 'gift_card.expires_on',
  AMOUNT: 'gift_card.amount',
  DISABLED: 'gift_card.disabled',
  NOT_FOUND: 'gift_card.not_found',
}

// Debug to log every keys
// console.log(
//   JSON.stringify(
//     [
//       GENERAL,
//       CONTACT,
//       BLOG,
//       GENDER,
//       PRODUCT,
//       FAQ,
//       FORM,
//       ACCOUNT,
//       CTA,
//       PANEL,
//       FILTER,
//       ERROR,
//       SEARCH,
//       ORDER,
//       ALERT,
//       LANG_SWITCHER,
//       BREADCRUMB,
//     ]
//       ?.map((item) => Object.values(item))
//       .flat(2)
//       ?.map((item) => (typeof item === 'function' ? item?.() : item)),
//     null,
//     1,
//   ),
// )
