import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface LinkListNavigationFooterProps {
  className?: string
  headline?: RichTextBlocks
  text?: RichTextBlocks
  links?: InlineCtaProps[]
}

export interface LinkListNavigationProps {
  className?: string
  links?: InlineCtaProps[]
  footer?: LinkListNavigationFooterProps
}

function LinkListNavigationFooter({
  className,
  headline,
  text,
  links,
}: LinkListNavigationFooterProps) {
  const headLineStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const hasFooter =
    isRTFilled(headline) || isRTFilled(text) || links?.length > 0

  return hasFooter ? (
    <div className={cx(css.LinkListNavigationFooter, className)}>
      <RichText className={cx(css.headline, headLineStyle)} render={headline} />

      <RichText
        className={cx(css.text, textStyle)}
        render={text}
        uppercaseLink={false}
        ctaPreset={GlobalTextPreset.Cta12Haffer}
      />

      {links?.length > 0 && (
        <div className={cx(css.links)}>
          {links?.map((link, index) => (
            <InlineCta
              key={`${link?.href}_${index}`}
              className={cx(css.link)}
              textPreset={GlobalTextPreset.Cta11Haffer}
              theme={
                link?.active
                  ? GlobalThemeColors.Black
                  : GlobalThemeColors.DoveGray
              }
              textStyling={GlobalTextStyling.UpperCase}
              {...link}
            />
          ))}
        </div>
      )}
    </div>
  ) : null
}

function LinkListNavigation({
  className,
  links,
  footer,
}: LinkListNavigationProps) {
  return (
    <div className={cx(css.LinkListNavigation, className)}>
      {links?.length > 0 && (
        <div className={cx(css.links)}>
          {links?.map((link, index) => {
            return link?.children?.length > 0 ? (
              <InlineCta
                key={`${link?.href}_${index}`}
                className={cx(css.link)}
                lineProps={{ initialLineState: 'none' }}
                textPreset={GlobalTextPreset.Cta12Haffer}
                theme={
                  link?.active
                    ? GlobalThemeColors.Black
                    : GlobalThemeColors.DoveGray
                }
                textStyling={GlobalTextStyling.UpperCase}
                {...link}
              />
            ) : null
          })}
        </div>
      )}

      <LinkListNavigationFooter className={cx(css.footer)} {...footer} />
    </div>
  )
}

LinkListNavigation.defaultProps = {}

export default LinkListNavigation
