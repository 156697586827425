import classnames from 'classnames/bind'
import { useEffect, useTransition } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LangSwitcherProps as AbstractLangSwitcherProps } from '~/components/Abstracts/LangSwitcher'
import { ArrowSelectIcon } from '~/components/Icons'
import MarketPanel from '~/components/Panels/MarketPanelWrapped'
import InlineCta from '~/components/UI/InlineCta'

import { useMarketContext } from '~/providers/MarketProvider'
import { usePanel } from '~/providers/PanelProvider'

import useLocale from '~/hooks/useLocale'
import useShop from '~/hooks/useShop'

import { LANG_SWITCHER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type { LangSwitcherProps } from '~/components/Abstracts/LangSwitcher'

type TLangSwitcherProps = {
  theme?: 'dark' | 'light'
} & AbstractLangSwitcherProps

function LangSwitcher({
  className,
  theme = 'dark',
  ...rest
}: TLangSwitcherProps) {
  const [, startTransition] = useTransition()
  const t = useTranslate()

  const locale = useLocale()
  const hasShop = useShop()
  const { displayMarketPanel } = useMarketContext()
  const { add: addPanel } = usePanel()

  const languageLabel = t(LANG_SWITCHER.LANGUAGE(locale))
  const currencySymbol = t(LANG_SWITCHER.LANGUAGE_SYMBOL(locale))

  const openMarketPanel = () => {
    startTransition(() => {
      addPanel({
        component: <MarketPanel />,
        context: 'market',
      })
    })
  }

  useEffect(() => {
    if (displayMarketPanel) {
      openMarketPanel()
    }
  }, [displayMarketPanel])

  return (
    <InlineCta
      hasLine={false}
      textStyling={[
        GlobalTextStyling.UpperCase,
        GlobalTextStyling.LetterSpacing2,
      ]}
      textPreset={GlobalTextPreset.Cta11Haffer}
      className={cx(css.LangSwitcher, className)}
      lineProps={{ isVisible: false }}
      onClick={openMarketPanel}
      theme={
        theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.White
      }
      {...rest}>
      {`${languageLabel}${hasShop ? ` (${currencySymbol})` : ''}`}
      <ArrowSelectIcon
        fill={theme === 'dark' ? 'black' : 'white'}
        className={css.icon}
      />
    </InlineCta>
  )
}

export default LangSwitcher
