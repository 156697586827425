import { useMotionValueEvent, useScroll } from 'framer-motion'
import { useState } from 'react'

const useScrollDirection = () => {
  const { scrollY } = useScroll()
  const [scrollDirection, setScrollDirection] = useState(null)

  useMotionValueEvent(scrollY, 'change', (current) => {
    const diff = current - scrollY.getPrevious()
    setScrollDirection(diff > 0 ? 'down' : 'up')
  })

  return scrollDirection
}

export default useScrollDirection
